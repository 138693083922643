import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberReferralsQueryBuilder
  implements IODataQueryBuilder {
  constructor(memberId: string) {
    this.entity = `HouseholdMembers(${memberId})/Referrals`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["User", "UserGroup", "Project($expand=IntakeForm)"];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(Project/Title, '${val}')`,
      `contains(User/Email, '${val}')`,
      `contains(UserGroup/Name, '${val}')`
    ];
    return arr.join(" or ");
  }
}
