import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectExtensionsQueryBuilder implements IODataQueryBuilder {
  constructor(projectId?: any) {
    if (projectId) {
      this.entity = `Projects(${projectId})/ProjectExtensions`;
    } else {
      this.entity = "Deliverables";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["File", "Donor", "Currency"];
  public readonly defaultFilter?: string;
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}