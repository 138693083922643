export default [
    {
      name: "name",
      required: true,
      label: "Company Name",
      align: "left",
      sortable: true,
      field: (i: any) => i.name
    },
    {
      name: "phoneNumber",
      required: true,
      label: "Contact Number",
      align: "left",
      sortable: true,
      field: (i: any) => i.phoneNumber
    },
    {
      name: "details",
      required: true,
      label: "Details",
      align: "left",
      sortable: true,
      field: (i: any) => i.details
    },
    {
      name: "employmentsectors",
      required: true,
      label: "Employment Sectors",
      align: "left",
      sortable: false,
      field: (i: any) => i.employmentSectors ? i.employmentSectors.map((o: any) => o.value).join(", ") : ''
    }
  ];