import IODataQueryBuilder from "../IODataQueryBuilder";

export default class CurrencyQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Currencies";
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [`contains(Value, '${val}') or Contains(Symbol, '${val}')`];
    return arr.join(" or ");
  }
}
