export default [
  {
    name: "title",
    required: true,
    label: "Title",
    align: "left",
    sortable: true,
    
    field: (i: any) => i.title
  },  {
    name: "programme/title",
    required: true,
    label: "Programme Title",
    align: "left",
    sortable: true,
    field: (i: any) => i.programme ? i.programme.title : ""
  },
  {
    name: "programme/type",
    required: true,
    label: "Programme Type",
    align: "left",
    sortable: true,
    field: (i: any) => i.programme ? i.programme.type : ""
  },
  {
    name: "type",
    required: true,
    label: "Type",
    align: "left",
    sortable: true,
    field: (i: any) => i.type
  },
  {
    name: "roleInPartnership/value",
    required: true,
    label: "Role",
    align: "left",
    sortable: true,
    field: (i: any) => i.roleInPartnership ? i.roleInPartnership.value : ''
  },
  {
    name: "startDate",
    required: true,
    label: "Start Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.startDate ? new Date(Date.parse(i.startDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "End Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.endDate ? new Date(Date.parse(i.endDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "fundAmount",
    required: true,
    label: "Project Grant Amount",
    align: "left",
    sortable: true,
    field: (i: any) => i.fundAmount
  },
  {
    name: "status",
    required: true,
    label: "Status",
    align: "left",
    sortable: false,
    field: (i: any) => + new Date(i.endDate) > Date.now() ? "Ongoing" : "Closed"
  },
  {
    name: "projectExtensions",
    required: true,
    label: "Extension",
    align: "left",
    sortable: false,
    field: (i: any) => i.projectExtensions && i.projectExtensions.length > 0 ? 'Yes' : 'No'
  },
  {
    name: "projectExtensionsEndDate",
    required: true,
    label: "Extension End Date",
    align: "left",
    sortable: false,
    field: (i: any) => i.projectExtensions && i.projectExtensions.length > 0 ? 
      new Date(i.projectExtensions.map((ext: any) => ext.endDate).filter((x: any) => x)
        .reduce((a: any, b: any) => a > b ? a : b)) : undefined,
     format: (val: Date) => (val ? val.toLocaleDateString() : "")
  }
];
