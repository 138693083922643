import IODataQueryBuilder from "../IODataQueryBuilder";

export default class SessionRequestSubmissionsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `Sessions(${id})/Submissions`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "Request($expand=Questionnaire)",
    "NextStep($expand=Questionnaire)",
    "RequestQuestionnaireSubmission",
    "NextStepQuestionnaireSubmission",
    "Session($expand=HouseholdMemberAction($expand=Project,ActionActionType($expand=Action,ActionType)))"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
