import IODataQueryBuilder from "../IODataQueryBuilder";

export default class DonorQueryBuilder implements IODataQueryBuilder {
  constructor() {
    this.entity = "Donors";
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(tolower(Name), '${val.toLocaleLowerCase()}')`
    ];
    return arr.join(" or ");
  }
}
