import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Route, RouteConfig } from "vue-router";
import lazyLoadView from "./lazyLoadView";

export default function crudRouteGenerator<T>(args: {
  viewPermission?: () => boolean;
  editPermission?: () => boolean;
  deletePermission?: () => boolean;
  addPermission?: () => boolean;
  contextCellName?: string;
  path: string;
  listRouteName: string;
  listTitle: string;
  columns: any[];
  extractions?: boolean;
  provider: DataProvider<T>;
  crud: ICrudClient<T>;
  addRouteName?: string;
  viewRouteName?: string;
  tableComponent?: Promise<{
    functional: boolean;
    render(h: any, { data, children }: any): any;
  }>;
  formComponent?: Promise<{
    functional: boolean;
    render(h: any, { data, children }: any): any;
  }>;
  props?: (r: Route) => any;
  children?: Array<RouteConfig>;
}): RouteConfig {
  return {
    path: args.path,
    meta: { listTitle: args.listTitle, listRouteName: args.listRouteName },
    component: () => lazyLoadView(import("@/views/SubPage.vue")),
    children: [
      {
        path: "",
        name: args.listRouteName,
        component: () =>
          args?.tableComponent ??
          lazyLoadView(import("@/components/ModelTable.vue")),
        props: (r: Route) => ({
          contextCellName: args.contextCellName,
          provider: args.provider,
          columns: args.columns,
          crud: args.crud,
          extractions: args.extractions,
          currentUser: r.params.currentUser,
          title: args.listTitle,
          viewPermission: args.viewPermission,
          editPermission: args.editPermission,
          deletePermission: args.deletePermission,
          addPermission: args.addPermission,
          addRoute: args.addRouteName
            ? () => ({ name: args.addRouteName, params: { id: "new" } })
            : undefined,
          viewRoute:
            args.viewRouteName || args.addRouteName
              ? (item: any) => ({
                  name: args.viewRouteName || args.addRouteName,
                  params: { id: item.id }
                })
              : undefined,
          ...(args.props ? args.props(r) : {})
        })
      },
      {
        path: ":id",
        name: args.viewRouteName || args.addRouteName,
        component: () => args.formComponent,
        props: (r: Route) => ({
          provider: args.provider,
          to: (id: any) => ({ name: args.listRouteName }),
          crud: args.crud
            ? args.crud
            : {
                saveAsync: () => {
                  return true;
                }
              },
          id: r.params.id,
          ...(args.props ? args.props(r) : {})
        })
      },
      ...(args.children ? args.children : [])
    ]
  };
}
