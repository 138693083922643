import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseHoldMembersByPermissionQueryBuilder
  implements IODataQueryBuilder {
  constructor(myCases: boolean) {
    this.entity = `GetHouseHoldMembersPermission(MyCases = ${myCases})`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "Gender",
    "Nationality",
    "LegalStatus",
    "HousingType",
    "HouseholdMemberRole",
    "Household",
    "InTakeFormSubmissions($expand=Project,CaseManagers)",
    "Files",
    "UserResponsible"
  ];
  public readonly expandOne = [];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(FirstName, '${val}')`,
      `contains(MiddleName, '${val}')`,
      `contains(LastName, '${val}')`,
      `contains(cast(DateOfBirth, Edm.String), '${val}')`,
      `contains(Nationality/Value, '${val}')`,
      `contains(LegalStatus/Value, '${val}')`,
      `contains(SocialNumber, '${val}')`,
      `contains(PhoneNumber, '${val}')`,
      `contains(Email, '${val}')`,
      `contains(HousingType/Value, '${val}')`,
      `contains(HouseholdMemberRole/Value, '${val}')`,
      `IntakeFormSubmissions/any(s: contains(s/Project/Title, '${val}'))`
    ];
    return arr.join(" or ");
  }
}
