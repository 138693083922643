import { Questionnaire } from "../models/Questionnaires";

export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "createdAt",
    required: true,
    label: "Created At",
    align: "left",
    sortable: true,
    field: (i: Questionnaire) => {
      if (i.createdAt) {
        return new Date(Date.parse(i.createdAt));
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },

  {
    name: "numberOfQuestions",
    required: true,
    label: "# of Questions",
    align: "right",
    sortable: false,
    field: (i: any) => {
      return i["questions@odata.count"];
    }
  },
  {
    name: "isFinal",
    required: true,
    label: "Final",
    align: "left",
    sortable: true,
    field: (i: any) => i.isFinal
  },
  {
    name: "isActivePrescreening",
    required: true,
    label: "Prescreening",
    align: "center",
    sortable: true,
    field: (i: any) => i.isActivePrescreening
  },
  {
    name: "isAdditionalBasicInformation",
    required: true,
    label: "Additional Information",
    align: "center",
    sortable: true,
    field: (i: any) => i.isAdditionalBasicInformation
  },
  {
    name: "questionnaireType",
    required: true,
    label: "Type",
    align: "left",
    sortable: false,
    field: (i: any) => i.questionnaireType ?? ""
  }
];
