import { Prescreening, Questionnaire } from "../models/Questionnaires";
import { User } from "../models/Users";

export default [
  {
    name: "prescreeningId",
    required: true,
    label: "Prescreening Id",
    align: "left",
    sortable: true,
    field: (i: any) => i.prescreeningId
  },
  {
    name: "questionnaireSubmission/submissionDate",
    required: true,
    label: "Created At",
    align: "left",
    sortable: true,
    field: (i: Prescreening) => {
      if (i.questionnaireSubmission) {
        return new Date(i.questionnaireSubmission.submissionDate);
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "questionnaireSubmission/user/firstName",
    required: true,
    label: "User",
    align: "right",
    sortable: true,
    field: (i: any) => {
      if (i.questionnaireSubmission.user) {
        return i.questionnaireSubmission.user;
      }
      return undefined;
    },
    format: (val: User) => (val ? val.firstName + " " + val.lastName : "")
  },
  {
    name: "assignedToUser/lastname",
    required: true,
    label: "Assigned To",
    align: "right",
    sortable: true,
    field: (i: any) => {
      if (i.assignedToUser) {
        return i.assignedToUser;
      }
      return undefined;
    },
    format: (val: User) => (val ? val.firstName + " " + val.lastName : "")
  },
  {
    name: "assignedDate",
    required: true,
    label: "Assigned Date",
    align: "right",
    sortable: true,
    field: (i: any) => {
      if (i.assignedDate) {
        return new Date(i.assignedDate);
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "questionnaireSubmission/answers/$count",
    required: true,
    label: "Answered Questions",
    align: "right",
    sortable: true,
    field: (i: any) => {
      if (i.questionnaireSubmission) {
        if (i.questionnaireSubmission.questionnaire) {
          return (
            (i.questionnaireSubmission["answers@odata.count"] || "") +
            "/" +
            (i.questionnaireSubmission.questionnaire["questions@odata.count"] ||
              "")
          );
        }
      }
      return "";
    }
  },
  {
    name: "ActionTaken",
    required: true,
    label: "Action Taken",
    align: "right",
    sortable: true,
    field: (i: any) => i.actionTaken
  },
  {
    name: "householdMember/firstName",
    required: true,
    label: "Assigned to Project",
    align: "right",
    sortable: true,
    field: (i: any) => {
      return i.householdMember ? "Yes" : "No";
    }
  },
  {
    name: "IsPersonEligibleForAtLeastOneProject",
    required: true,
    label: "Eligibility",
    align: "right",
    sortable: true,
    field: (i: any) => {
      return i.isPersonEligibleForAtLeastOneProject ? "Yes" : "No";
    }
  },
  {
    name: "Comment",
    required: true,
    label: "Comment",
    align: "right",
    sortable: true,
    field: (i: any) => {
      return i.Comment;
    }
  }
];
