import IODataQueryBuilder from "../IODataQueryBuilder";

export default class UploadedFileQueryBuilder
  implements IODataQueryBuilder {
  public readonly entity = "UploadedFiles";
  public readonly select = ["Id", "Name"];
  public readonly expand = ["Answer", "Consent"];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
