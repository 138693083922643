import IODataQueryBuilder from "../IODataQueryBuilder";

export default class STrategicCommunicationQueryBuilder
  implements IODataQueryBuilder {
  public readonly entity = "StrategicCommunications";
  public readonly select = [];
  public readonly expand = ["CommunicationType", "Donors", "Projects"];
  public readonly expandOne = [
    "ThematicCategories",
    "AuthorityAppeal($expand=Beneficiaries,AppealType)",
    "CommunicationType($expand=Metrics)",
    "OutreachRecords($expand=MetricValues)",
    "HR360Contributors",
    "Organizers",
    "Attachments",
    "Donors",
    "Projects"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Title, '${val}')`];
    return arr.join(" or ");
  }
}
