import IODataQueryBuilder from "../IODataQueryBuilder";

export default class FollowUpQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "FollowUp";
  public readonly select = [];
  public readonly expand = [
    "Action",
    "Questionnaire($expand=Questions($orderby=Priority asc;$expand=Options($orderby=Priority asc),BoundTo($expand=Options,BoundQuestion)))"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Text, '${val}')`];
    return arr.join(" or ");
  }
}
