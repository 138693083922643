import IODataQueryBuilder from "../IODataQueryBuilder";

export default class MonitoringSessionQueryBuilder
  implements IODataQueryBuilder {
  constructor(id = "") {
    if (id) {
      this.entity = `Projects(${id})/MonitoringSessions`;
    } else {
      this.entity = `MonitoringSessions`;
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [];
  public readonly expandOne = [
    "Submission($expand=Answers($expand=Options,Tuples,File))"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Promoter, '${val}')`];
    return arr.join(" or ");
  }
}
