import IODataQueryBuilder from "../IODataQueryBuilder";

export default class IndicatorValuesQueryBuilder implements IODataQueryBuilder {
  constructor(id?: string) {
    if (id) {
      this.entity = `Indicators(${id})/IndicatorValues`;
    } else {
      this.entity = "IndicatorValues";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Indicator", "Period"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
