import IODataQueryBuilder from "../IODataQueryBuilder";

export default class MrrSubmissionQueryBuilder
  implements IODataQueryBuilder {
    constructor(entity?: string, id?: string) {
      if (entity && id) {
        this.entity = `${entity}(${id})/MrrSubmissions`;
      } else if (id) {
        this.entity = `MrrSubmissions${id}`
      } 
      else {
        this.entity = "MrrSubmissions";
      }
    }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "MrrPeriod",
  ];
  public readonly expandOne = [
    "MrrPeriod",
    "QuestionnaireSubmission($expand=Questionnaire($expand=Questions($orderby=Priority asc;$expand=Options($orderby=Priority))),Answers($expand=Options,Tuples,File))"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}