import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ApplicantQueryBuilder implements IODataQueryBuilder {
  constructor(id = "") {
    if (id) {
      this.entity = `OpenCalls(${id})/Applicants`;
    } else {
      this.entity = "Applicants";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "SubmissionFile",
    "DecisionFile",
    "EligibilityQuestionnaireSubmission",
    "Scores",
    "Appeals($top=0;$count=true)",
    "Partners($count=true)"
  ];
  public readonly expandOne = [
    "SubmissionFile",
    "DecisionFile",
    "EligibilityQuestionnaireSubmission",
    "Scores",
    "Appeals($top=0;$count=true)",
    "Partners"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
