import IODataQueryBuilder from "../IODataQueryBuilder";

export default class RiskCategoriesQueryBuilder implements IODataQueryBuilder {
  public readonly entity: string = "RiskCategories";
  public readonly select = [];
  public readonly expand = ["Risks($top=0;$count=true)"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
