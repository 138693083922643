import { AxiosInstance } from "axios";
import { User, UserManager, UserManagerSettings } from "oidc-client";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import { getPermissions } from "@/lib/permissions";
import Vue from "vue";

export default class AuthService {
  private userManager: UserManager;
  private refreshToken = "";
  public userInfo: any = null;
  private parsedPermissions: any;
  public permissions: { [index: string]: any } = {};

  constructor(
    settings: UserManagerSettings,
    private readonly _axios: AxiosInstance
  ) {
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(user => {
      this.handleUser(user);
    });
    this.userManager.events.addSilentRenewError(e => {
      this.userManager.signinRedirect();
    });
    this.userManager.events.addAccessTokenExpiring(async () => {
      try {
        //await this.silentRefresh();
      } catch (error) {
        console.error("Access Token Expiring silent renew error", error);
        this.userManager.signinRedirect();
      }
    });
  }

  permissionsChangedCallbacks: Function[] = [];
  onPermissionsChanged(callback: Function) {
    this.permissionsChangedCallbacks.push(callback);
  }

  handleUser(user: any) {
    if (user.profile.permission) {
      this.permissions = Vue.observable(
        getPermissions(JSON.parse(user.profile.permission))
      );
    }
    console.log("user", user, this.permissions);
    this.refreshToken = user.refresh_token;
  }

  async silentRefresh() {
    try {
      const user = await this.userManager.signinSilent();
      this.handleUser(user);
      return user;
    } catch (error) {
      console.error("silentRefresh：Refresh failed", error);
      throw error;
    }
  }
  public async getUser(): Promise<User | null> {
    const res = await this.userManager.getUser();
    return res;
  }
  public perm(callback: (perms: any) => boolean): () => boolean {
    return () => this.permissions.admin.is || callback(this.permissions);
  }
  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public async isAuthenticated(): Promise<boolean> {
    const user = await this.userManager.getUser();
    this.userInfo = user;
    if (user !== null) {
      if (!user.expired) {
        try {
          this.handleUser(user);
        } catch (e) {
          console.error(e);
          await new Promise(resolve => {
            setTimeout(resolve, 10000);
          });
          await this.userManager.signoutRedirect();
          throw "Logging out";
        }
      }
      return !user.expired;
    }
    return false;
  }

  public async getAccessToken(): Promise<string> {
    try {
      const data = await this.userManager.getUser();
      if (data) {
        return data.access_token;
      } else {
        await this.userManager.signinRedirect();
      }
    } catch {
      await this.userManager.signinRedirect();
    }
    return "";
  }
  private hook!: Function;
  public hookRouter(router: VueRouter) {
    this.hook = router.beforeEach(this.routerHookCallback);
  }

  public unhookRouter() {
    this.hook();
  }

  private routerHookCallback(
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>
  ) {
    if (to.name !== "Authentication") {
      next("/authentication?redirect=" + encodeURIComponent(to.path));
    } else {
      next();
    }
  }
}
