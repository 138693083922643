import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberIntakeFormSubmissionsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `HouseholdMembers(${id})/IntakeFormSubmissions`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "Project",
    "QuestionnaireSubmission",
    "Consent($expand=Submission)"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
