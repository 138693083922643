import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=0f38255e&"
import script from "./Loading.vue?vue&type=script&lang=ts&"
export * from "./Loading.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCircularProgress});
