import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Projects";
  public readonly select = [];
  public readonly expand = [
    "CommunicationActivities($count=true;$top=0)",
    "RoleInPartnership",
    "ConsentForm($expand=Files)",
    "Programme($select=type,title;$expand=currency($select=symbol))",
    "ProjectExtensions($select=endDate)"
  ];

  public readonly expandOne = [
    "Programme",
    "Donations($expand=Donor,Currency)",
    "Contract",
    "Timeplan",
    "FinancialReports",
    "NarrativeReports",
    "CallForTenders",
    "RoleInPartnership",
    "IntakeForm",
    "MonitoringForm",
    "MRR",
    "Location",
    "ProjectManagers",
    "CommunicationActivities($count=true;$top=0)",
    "RiskAssessment($expand=Categories($expand=Risks))",
    "OpenCall",
    "Applicant",
    "ConsentForm($expand=Files)",
    "MrrSubmissions",
    "DevelopmentPlans"
  ];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(Title, '${val}')`,
      `contains(cast(StartDate, Edm.String), '${val}')`,
      `contains(cast(EndDate, Edm.String), '${val}')`,
    ];
    return arr.join(" or ");
  }
}
