import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ConsentFormQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "ConsentForms";
  public readonly select = [];
  public readonly expand = ["Files", "Questionnaire"];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
