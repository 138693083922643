import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectPartnersQueryBuilder implements IODataQueryBuilder {
  constructor(projectId?: any) {
    if (projectId) {
      this.entity = `Projects(${projectId})/ProjectPartners`;
    } else {
      this.entity = "ProjectPartners";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Currency", "Deliverables", "Timeplan", "Contract"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
