import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ReferralQueryBuilder implements IODataQueryBuilder {
  constructor(filter?: any) {
     this.defaultFilter = filter;
  }
  public readonly defaultFilter: string;
  public readonly entity = "Referrals";
  public readonly select = [];
  public readonly expand = [
    "HouseholdMember",
    "UserGroup",
    "User",
    "Project"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}