import IODataQueryBuilder from "@/lib/odata/IODataQueryBuilder";

export default class ExtractionProjectsQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Projects";
  public readonly select = [];
  public readonly expand = [
    "RoleInPartnership"
  ];
  public filter(val: string): string {
    const arr: string[] = [
    ];
    return arr.join(" or ");
  }
}