import IODataQueryBuilder from "../IODataQueryBuilder";

export default class UserQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Users";
  public readonly select = [];
  public readonly expand = ["UserGroup($select=Name)", "Programs"];
  public readonly expandOne = ["UserGroup", "Programs"];
  public filter(val: string): string {
    const arr: string[] = [`contains(FirstName, '${val}') or contains(LastName, '${val}') or contains(Email, '${val}') `];
    return arr.join(" or ");
  }
}
