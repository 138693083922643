import IODataQueryBuilder from "../IODataQueryBuilder";

export default class MonitoringFindingQueryBuilder
  implements IODataQueryBuilder {
  constructor(id = "") {
    if (id) {
      this.entity = `Projects(${id})/MonitoringFindings`;
    } else {
      this.entity = `MonitoringFindings`;
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Type"];
  public filter(val: string): string {
    const arr: string[] = [`contains(Description, '${val}')`];
    return arr.join(" or ");
  }
}
