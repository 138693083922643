import {
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import {
  CommunicationType,
  StrategicCommunication
} from "@/data/models/StrategicCommunication";
import { Location } from "@/data/models/Locations";
import { RelatedToRisk } from "@/data/models/RelatedToRisks";
import { AxiosInstance } from "axios";
import DataProvider from "../DataProvider";
import ODataProvider from "./ODataProvider";
import CommunicationTypesQueryBuilder from "./query/CommunicationTypesQueryBuilder";
import ProjectCommunicationActivityQueryBuilder from "./query/ProjectCommunicationActivityQueryBuilder";
import StrategicCommunicationQueryBuilder from "./query/StrategicCommunicationQueryBuilder";
import QuestionnaireSubmissionQueryBuilder from "./query/QuestionnaireSubmissionQueryBuilder";
import {
  Applicant,
  Criterion,
  FinalScore,
  OpenCall,
  Score
} from "@/data/models/OpenCall";
import OpenCallQueryBuilder from "./query/OpenCallQueryBuilder";
import ApplicantQueryBuilder from "./query/ApplicantQueryBuilder";
import FinalScoreQueryBuilder from "./query/FinalScoreQueryBuilder";
import ScoreQueryBuilder from "./query/ScoreQueryBuilder";
import CriterionQueryBuilder from "./query/CriterionQueryBuilder";
import { ActivityRecord } from "@/data/models/ActivityRecord";
import ActivityRecordQueryBuilder from "./query/ActivityRecordQueryBuilder";
import { ActionActionType } from "@/data/models/ActionActionType";
import ActionActionTypeQueryBuilder from "./query/ActionActionTypeQueryBuilder";
import { Activity } from "@/data/models/Activity";
import ActivityQueryBuilder from "./query/ActivityQueryBuilder";
import { HouseholdType } from "@/data/models/HouseholdType";
import EnumQueryBuilder from "./query/EnumQueryBuilder";
import { UploadedFile } from "@/data/models/UploadedFiles";
import UploadedFileQueryBuilder from "./query/UploadedFileQueryBuilder";
import { LegalStatus } from "@/data/models/LegalStatus";
import { Nationality } from "@/data/models/Nationalities";
import { HouseholdMemberRole } from "@/data/models/HouseholdMemberRoles";
import { HousingType } from "@/data/models/HousingTypes";
import { Donor, Programme } from "@/data/models/Programs";
import DonorQueryBuilder from "./query/DonorQueryBuilder";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import HouseholdMemberQueryBuilder from "./query/HouseholdMemberQueryBuilder";
import { Project } from "@/data/models/Projects";
import ProjectQueryBuilder from "./query/ProjectQueryBuilder";
import CommunicationActivityQueryBuilder from "./query/CommunicationActivityQueryBuilder";
import { PrescreeningActionTaken } from "@/data/models/PrescreeningActionTaken";
import QuestionnaireQueryBuilder from "./query/QuestionnaireQueryBuilder";
import ProgrammeQueryBuilder from "./query/ProgrammeQueryBuilder";
import { Template } from "@/data/models/Templates";
import TemplateQueryBuilder from "./query/TemplateQueryBuilder";
import { Currency } from "@/data/models/Currencies";
import CurrencyQueryBuilder from "./query/CurrencyQueryBuilder";
import { IndicatorType } from "@/data/models/IndicatorTypes";
import { TargetType } from "@/data/models/TargetTypes";
import { Gender } from "@/data/models/Genders";
import { AgeRanges } from "@/data/models/AgeRanges";
import { Citizenships } from "@/data/models/Citizenships";
import { User } from "@/data/models/Users";
import UserQueryBuilder from "./query/UserQueryBuilder";
import UserGroupUsersQueryBuilder from "./query/UserGroupUsersQueryBuilder";
import AssessmentRiskCategoriesQueryBuilder from "./query/AssessmentRiskCategoriesQueryBuilder";
import RiskCategoryRiskQueryBuilder from "./query/RiskCategoryRiskQueryBuilder";
import UserGroupQueryBuilder from "./query/UserGroupQueryBuilder";
import PrescreeningQueryBuilder from "./query/PrescreeningQueryBuilder";
import { ActionType } from "@/data/models/ActionTypes";
import ActionTypeQueryBuilder from "./query/ActionTypeQueryBuilder";
import { Action } from "@/data/models/Actions";
import ActionQueryBuilder from "./query/ActionQueryBuilder";
import SessionQueryBuilder from "./query/SessionQueryBuilder";
import PurposeQueryBuilder from "./query/PurposeQueryBuilder";
import SessionRequestQueryBuilder from "./query/SessionRequestQueryBuilder";
import NextStepQueryBuilder from "./query/NextStepQueryBuilder";
import HouseholdMemberActionsQueryBuilder from "./query/HouseholdMemberActionsQueryBuilder";
import SessionRequestSubmissionsQueryBuilder from "./query/SessionRequestSubmissionsQueryBuilder";
import HouseholdMemberActionSessionsQueryBuilder from "./query/HouseholdMemberActionSessionsQueryBuilder";
import RiskQueryBuilder from "./query/RiskQueryBuilder";
import RiskCategoriesQueryBuilder from "./query/RiskCategoriesQueryBuilder";
import RequestSubmissionsQueryBuilder from "./query/RequestSubmissionsQueryBuilder";
import SettingsQueryBuilder from "./query/SettingsQueryBuilder";
import HouseholdMemberReferralsQueryBuilder from "./query/HouseholdMemberReferralsQueryBuilder";
import { ConsentForm } from "@/data/models/ConsentForm";
import ConsentFormQueryBuilder from "./query/ConsentFromQueryBuilder";
import AppealQueryBuilder from "./query/AppealQueryBuilder";
import MonitoringFindingQueryBuilder from "./query/MonitoringFindingQueryBuilder";
import MonitoringSessionQueryBuilder from "./query/MonitoringSessionQueryBuilder";
import PartnerQueryBuilder from "./query/PartnerQueryBuilder";
import HouseholdMemberIntakeFormSubmissionsQueryBuilder from "./query/HouseholdMemberIntakeFormSubmissionsQueryBuilder";
import HouseholdMemberNotesQueryBuilder from "./query/HouseholdMemberNotesQueryBuilder";
import HouseholdMemberSubmissionsQueryBuilder from "./query/HouseholdMemberSubmissionsQueryBuilder";
import FollowUpQueryBuilder from "./query/FollowUpQueryBuilder";
import HouseholdMemberRequestsQueryBuilder from "./query/HouseholdMemberRequestsQueryBuilder";
import FollowUpSubmissionQueryBuilder from "./query/FollowUpSubmissionQueryBuilder";
import ProgrammeDonationsQueryBuilder from "./query/ProgrammeDonationsQueryBuilder";
import ActivityTypeQueryBuilder from "./query/ActivityTypeQueryBuilder";
import NestedMeetingsQueryBuilder from "./query/NestedMeetingsQueryBuilder";
import ProjectIndicatorsQueryBuilder from "./query/ProjectIndicatorsQueryBuilder";
import ProgrammeIndicatorsQueryBuilder from "./query/ProgrammeIndicatorsQueryBuilder";
import IndicatorQueryBuilder from "./query/IndicatorQueryBuilder";
import IndicatorValuesQueryBuilder from "./query/IndicatorValuesQueryBuilder";
import MrrSubmissionQueryBuilder from "./query/MrrSubmissionsQueryBuilder";
import IndicatorValueDetailsQueryBuilder from "./query/IndicatorValueDetailsQueryBuilder";
import ProgrammePeriodsQueryBuilder from "./query/ProgrammePeriodsQueryBuilder";
import ActivityRecordTableQueryBuilder from "./query/ActivityRecordTableQueryBuilder";
import IntakeFormProjectQueryBuilder from "./query/IntakeFormPorjectQueyBuilder";
import ParentCriterionQueryBuilder from "./query/ParentCriterionQueryBuilder";
import ChildCriterionQueryBuilder from "./query/ChildCriterionQueryBuilder";
import { ResultFrameworkBreakdown } from "@/data/models/ResultFrameworkBreakDown";
import ResultFrameworkBreakdownsQueryBuilder from "./query/ResultFrameworkBreakdownBuilder";
import { filter } from "vue/types/umd";
import { isDate } from "moment";
import { EmploymentSector } from "@/data/models/EmploymentSector";
import { Company } from "@/data/models/Company";
import CompanyQueryBuilder from "./query/CompanyQueryBuilder";
import OpenCallFilesQueryBuilder from "./query/OpenCallFilesQueryBuilder";
import ExtractionProjectsQueryBuilder from "./query/ExtractionQueries/ExtractionProjectsQueryBuilder";
import ExtractionProgrammsQueryBuilder from "./query/ExtractionQueries/ExtractionProgrammsQueryBuilder";
import ExtractionUsersQueryBuilder from "./query/ExtractionQueries/ExtractionUsersQueryBuilder";
import RFFileQueryBuilder from "./query/RRFileQueryBuilder";
import DeliverableQueryBuilder from "./query/ProjectDeliverableQueryBuilder";
import ProjectExtensionsQueryBuilder from "./query/ProjectExtensionsQueryBuilder";
import ProjectPartnersQueryBuilder from "./query/ProjectPartnersQueryBuilder";
import ReferralQueryBuilder from "./query/ReferralQueryBuilder";
import ProjectManagerUsersQueryBuilder from "./query/ProjectManagerUsersQueryBuilder";
import HouseHoldMembersByPermissionQueryBuilder from "./query/HouseHoldMembersByPermissionQueryBuilder";

class EnumProviderMap {
  constructor(private readonly axios: AxiosInstance) {}
  private readonly dict: { [k: string]: DataProvider<any> } = {};
  get(a: any, b: any, c: any) {
    if (!this.dict[b]) {
      this.dict[b] = new ODataProvider<any>(
        this.axios,
        new EnumQueryBuilder(b)
      );
    }
    return this.dict[b];
  }
}

export default class ODataProviders {
  constructor(private readonly axios: AxiosInstance) {
    this.communicationTypes = new ODataProvider<CommunicationType>(
      this.axios,
      new CommunicationTypesQueryBuilder()
    );
    this.resultFrameworkBreakdowns = new ODataProvider<
      ResultFrameworkBreakdown
    >(this.axios, new ResultFrameworkBreakdownsQueryBuilder());
    this.programmesPeriods = new ODataProvider<any>(
      this.axios,
      new ProgrammePeriodsQueryBuilder()
    );
    this.strategicCommunications = new ODataProvider<StrategicCommunication>(
      this.axios,
      new StrategicCommunicationQueryBuilder()
    );
    this.alwaysAvailableQuestionnaireSubmissions = new ODataProvider<
      QuestionnaireSubmission
    >(
      this.axios,
      new QuestionnaireSubmissionQueryBuilder(),
      "Questionnaire/QuestionnaireType eq 'AlwaysAvailable'"
    );
    this.openCalls = new ODataProvider<OpenCall>(
      this.axios,
      new OpenCallQueryBuilder()
    );
    this.consentForms = new ODataProvider<ConsentForm>(
      this.axios,
      new ConsentFormQueryBuilder()
    );
    this.applicants = new ODataProvider<Applicant>(
      this.axios,
      new ApplicantQueryBuilder()
    );

    this.partners = new ODataProvider<any>(
      this.axios,
      new PartnerQueryBuilder()
    );

    this.finalScores = new ODataProvider<FinalScore>(
      this.axios,
      new FinalScoreQueryBuilder()
    );

    this.scores = new ODataProvider<Score>(this.axios, new ScoreQueryBuilder());

    this.criteria = new ODataProvider<Criterion>(
      this.axios,
      new CriterionQueryBuilder()
    );

    this.activityRecords = new ODataProvider<ActivityRecord>(
      this.axios,
      new ActivityRecordQueryBuilder()
    );

    this.activityRecordsTable = new ODataProvider<ActivityRecord>(
      this.axios,
      new ActivityRecordTableQueryBuilder()
    );

    this.actionActionTypes = new ODataProvider<ActionActionType>(
      this.axios,
      new ActionActionTypeQueryBuilder()
    );

    this.activities = new ODataProvider<Activity>(
      this.axios,
      new ActivityQueryBuilder()
    );

    this.householdTypes = new ODataProvider<HouseholdType>(
      this.axios,
      new EnumQueryBuilder("HouseholdTypes")
    );

    this.uploadedFiles = new ODataProvider<UploadedFile>(
      this.axios,
      new UploadedFileQueryBuilder()
    );

    this.legalStatuses = new ODataProvider<LegalStatus>(
      this.axios,
      new EnumQueryBuilder("LegalStatuses")
    );

    this.nationalities = new ODataProvider<Nationality>(
      this.axios,
      new EnumQueryBuilder("Nationalities")
    );

    this.employmentSectors = new ODataProvider<EmploymentSector>(
      this.axios,
      new EnumQueryBuilder("EmploymentSectors")
    );

    this.companies = new ODataProvider<Company>(
      this.axios,
      new CompanyQueryBuilder()
    );

    this.householdMemberRoles = new ODataProvider<HouseholdMemberRole>(
      this.axios,
      new EnumQueryBuilder("HouseholdMemberRoles")
    );

    this.housingTypes = new ODataProvider<HousingType>(
      this.axios,
      new EnumQueryBuilder("HousingTypes")
    );

    this.donors = new ODataProvider<Donor>(this.axios, new DonorQueryBuilder());

    this.householdMembers = new ODataProvider<HouseholdMember>(
      this.axios,
      new HouseholdMemberQueryBuilder()
    );

    this.questionnaireSubmissions = new ODataProvider<QuestionnaireSubmission>(
      this.axios,
      new QuestionnaireSubmissionQueryBuilder()
    );

    this.projects = new ODataProvider<Project>(
      this.axios,
      new ProjectQueryBuilder()
    );

    this.intakeFormProjects = new ODataProvider<Project>(
      this.axios,
      new IntakeFormProjectQueryBuilder()
    );

    this.communicationActivities = new ODataProvider<any>(
      this.axios,
      new CommunicationActivityQueryBuilder()
    );

    this.communicationActivityValues = new ODataProvider<any>(
      this.axios,
      new EnumQueryBuilder("CommunicationActivityValues")
    );

    this.prescreeningActionTaken = new ODataProvider<PrescreeningActionTaken>(
      this.axios,
      new EnumQueryBuilder("PrescreeningActionTaken")
    );

    this.questionnaires = new ODataProvider<Questionnaire>(
      this.axios,
      new QuestionnaireQueryBuilder()
    );

    this.programs = new ODataProvider<Programme>(
      this.axios,
      new ProgrammeQueryBuilder()
    );

    this.templates = new ODataProvider<Template>(
      this.axios,
      new TemplateQueryBuilder()
    );

    this.locations = new ODataProvider<Location>(
      this.axios,
      new EnumQueryBuilder("Locations")
    );

    this.relatedToRisks = new ODataProvider<RelatedToRisk>(
      this.axios,
      new EnumQueryBuilder("RelatedToRisks")
    );

    this.currencies = new ODataProvider<Currency>(
      this.axios,
      new CurrencyQueryBuilder()
    );

    this.indicatorTypes = new ODataProvider<IndicatorType>(
      this.axios,
      new EnumQueryBuilder("IndicatorTypes")
    );

    this.targetTypes = new ODataProvider<TargetType>(
      this.axios,
      new EnumQueryBuilder("TargetTypes")
    );

    this.genders = new ODataProvider<Gender>(
      this.axios,
      new EnumQueryBuilder("Genders")
    );

    this.AgeRanges = new ODataProvider<AgeRanges>(
      this.axios,
      new EnumQueryBuilder("AgeRanges")
    );

    this.Citizenships = new ODataProvider<Citizenships>(
      this.axios,
      new EnumQueryBuilder("Citizenships")
    );
    this.users = new ODataProvider<User>(this.axios, new UserQueryBuilder());

    this.userGroups = new ODataProvider<User>(
      this.axios,
      new UserGroupQueryBuilder()
    );

    this.prescreenings = new ODataProvider<Prescreening>(
      this.axios,
      new PrescreeningQueryBuilder()
    );

    this.actionTypes = new ODataProvider<ActionType>(
      this.axios,
      new ActionTypeQueryBuilder()
    );

    this.actions = new ODataProvider<Action>(
      this.axios,
      new ActionQueryBuilder()
    );

    this.sessions = new ODataProvider<any>(
      this.axios,
      new SessionQueryBuilder()
    );

    this.purposes = new ODataProvider<any>(
      this.axios,
      new PurposeQueryBuilder()
    );

    this.activityTypes = new ODataProvider<any>(
      this.axios,
      new ActivityTypeQueryBuilder()
    );

    this.sessionRequests = new ODataProvider<any>(
      this.axios,
      new SessionRequestQueryBuilder()
    );

    this.nextSteps = new ODataProvider<any>(
      this.axios,
      new NextStepQueryBuilder()
    );

    this.risks = new ODataProvider<any>(this.axios, new RiskQueryBuilder());

    this.riskCategories = new ODataProvider<any>(
      this.axios,
      new RiskCategoriesQueryBuilder()
    );

    this.requestSubmissions = new ODataProvider<any>(
      this.axios,
      new RequestSubmissionsQueryBuilder()
    );

    this.settings = new ODataProvider<any>(
      this.axios,
      new SettingsQueryBuilder()
    );

    this.appeals = new ODataProvider<any>(this.axios, new AppealQueryBuilder());
    this.monitoringFindings = new ODataProvider<any>(
      this.axios,
      new MonitoringFindingQueryBuilder()
    );

    this.monitoringSessions = new ODataProvider<any>(
      this.axios,
      new MonitoringSessionQueryBuilder()
    );

    this.followUp = new ODataProvider<any>(
      this.axios,
      new FollowUpQueryBuilder()
    );

    this.followUpSubmissions = new ODataProvider<any>(
      this.axios,
      new FollowUpSubmissionQueryBuilder()
    );

    this.meetings = new ODataProvider<any>(
      this.axios,
      new NestedMeetingsQueryBuilder()
    );

    this.mrrSubmissions = new ODataProvider<any>(
      this.axios,
      new MrrSubmissionQueryBuilder()
    );

    this.indicators = new ODataProvider<any>(
      this.axios,
      new IndicatorQueryBuilder()
    );

    this.openCallFiles = new ODataProvider<any>(
      this.axios,
      new OpenCallFilesQueryBuilder()
    );
    
    this.extractionProjects = new ODataProvider<any>(
      this.axios,
      new ExtractionProjectsQueryBuilder()
    );

    this.extractionProgramms = new ODataProvider<any>(
      this.axios,
      new ExtractionProgrammsQueryBuilder()
    );

    this.extractionUsers = new ODataProvider<any>(
      this.axios,
      new ExtractionUsersQueryBuilder()
    );

    this.rfFiles = new ODataProvider<any>(
      this.axios,
      new RFFileQueryBuilder()
    )

    this.mapForEnums = new Proxy(this, new EnumProviderMap(this.axios));
  }

  readonly communicationTypes: DataProvider<CommunicationType>;
  readonly resultFrameworkBreakdowns: DataProvider<ResultFrameworkBreakdown>;
  readonly strategicCommunications: DataProvider<StrategicCommunication>;

  readonly alwaysAvailableQuestionnaireSubmissions: DataProvider<
    QuestionnaireSubmission
  >;

  readonly openCalls: DataProvider<OpenCall>;

  readonly consentForms: DataProvider<ConsentForm>;

  readonly applicants: DataProvider<Applicant>;

  readonly partners: DataProvider<any>;

  readonly finalScores: DataProvider<FinalScore>;

  readonly scores: DataProvider<Score>;

  readonly criteria: DataProvider<Criterion>;

  readonly activityRecords: DataProvider<ActivityRecord>;

  readonly activityRecordsTable: DataProvider<ActivityRecord>;

  readonly actionActionTypes: DataProvider<ActionActionType>;

  readonly activities: DataProvider<Activity>;

  readonly householdTypes: DataProvider<HouseholdType>;

  readonly uploadedFiles: DataProvider<UploadedFile>;

  readonly legalStatuses: DataProvider<LegalStatus>;

  readonly nationalities: DataProvider<Nationality>;
  readonly employmentSectors: DataProvider<EmploymentSector>;
  readonly companies: DataProvider<Company>;
  readonly householdMemberRoles: DataProvider<HouseholdMemberRole>;

  readonly housingTypes: DataProvider<HousingType>;

  readonly donors: DataProvider<Donor>;

  readonly householdMembers: DataProvider<HouseholdMember>;

  readonly questionnaireSubmissions: DataProvider<QuestionnaireSubmission>;

  readonly projects: DataProvider<Project>;

  readonly intakeFormProjects: DataProvider<Project>;
  readonly programmesPeriods: DataProvider<any>;

  readonly communicationActivities: DataProvider<any>;

  readonly communicationActivityValues: DataProvider<any>;

  readonly prescreeningActionTaken: DataProvider<PrescreeningActionTaken>;

  readonly questionnaires: DataProvider<Questionnaire>;

  readonly programs: DataProvider<Programme>;

  readonly templates: DataProvider<Template>;

  readonly locations: DataProvider<Location>;
  readonly relatedToRisks: DataProvider<RelatedToRisk>;

  readonly currencies: DataProvider<Currency>;

  readonly indicatorTypes: DataProvider<IndicatorType>;

  readonly targetTypes: DataProvider<TargetType>;

  readonly genders: DataProvider<Gender>;

  readonly AgeRanges: DataProvider<AgeRanges>;

  readonly Citizenships: DataProvider<Citizenships>;

  readonly users: DataProvider<User>;

  readonly userGroups: DataProvider<User>;

  readonly prescreenings: DataProvider<Prescreening>;

  readonly actionTypes: DataProvider<ActionType>;

  readonly actions: DataProvider<Action>;

  readonly sessions: DataProvider<any>;

  readonly purposes: DataProvider<any>;

  readonly activityTypes: DataProvider<any>;

  readonly sessionRequests: DataProvider<any>;
  readonly mrrSubmissions: DataProvider<any>;

  readonly nextSteps: DataProvider<any>;

  readonly risks: DataProvider<any>;

  readonly riskCategories: DataProvider<any>;

  readonly requestSubmissions: DataProvider<any>;

  readonly settings: DataProvider<any>;

  readonly appeals: DataProvider<any>;

  readonly monitoringFindings: DataProvider<any>;

  readonly monitoringSessions: DataProvider<any>;

  readonly followUp: DataProvider<any>;

  readonly followUpSubmissions: DataProvider<any>;

  readonly meetings: DataProvider<any>;

  readonly indicators: DataProvider<any>;

  readonly openCallFiles: DataProvider<any>;

  readonly extractionProjects: DataProvider<any>;
  readonly extractionProgramms: DataProvider<any>;
  readonly extractionUsers: DataProvider<any>;
  readonly rfFiles: DataProvider<any>;

  indicatorValues(id?: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new IndicatorValuesQueryBuilder(id)
    );
  }

  indicatorValueDetails(id?: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new IndicatorValueDetailsQueryBuilder(id)
    );
  }

  userGroupUsers(userGroupId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new UserGroupUsersQueryBuilder(userGroupId)
    );
  }

  projectRiskAssessmentCategories(id: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new AssessmentRiskCategoriesQueryBuilder(id)
    );
  }

  projectIndicators(id: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProjectIndicatorsQueryBuilder(id)
    );
  }

  programmeIndicators(id: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProgrammeIndicatorsQueryBuilder(id)
    );
  }

  programmePeriods(id: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProgrammePeriodsQueryBuilder(id)
    );
  }

  riskCategoryRisks(id: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new RiskCategoryRiskQueryBuilder(id)
    );
  }

  projectCommunicationActivities(project: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProjectCommunicationActivityQueryBuilder(project)
    );
  }
  projectMonitoringFindings(projectId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new MonitoringFindingQueryBuilder(projectId)
    );
  }
  projectMonitoringSessions(projectId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new MonitoringSessionQueryBuilder(projectId)
    );
  }

  openCallAppeals(openCallId: string, applicantId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new AppealQueryBuilder(openCallId),
      applicantId ? `ApplicantId eq ${applicantId}` : undefined
    );
  }

  openCallCriteria(openCallId: string): DataProvider<Criterion> {
    return new ODataProvider<Criterion>(
      this.axios,
      new CriterionQueryBuilder(openCallId)
    );
  }

  openCallParentCriteria(openCallId: string): DataProvider<Criterion> {
    return new ODataProvider<Criterion>(
      this.axios,
      new ParentCriterionQueryBuilder(openCallId)
    );
  }

  openCallChildCriteria(
    openCallId: string,
    parentId: string
  ): DataProvider<Criterion> {
    return new ODataProvider<Criterion>(
      this.axios,
      new ChildCriterionQueryBuilder(openCallId, parentId)
    );
  }

  openCallApplicant(openCallId: string): DataProvider<Applicant> {
    return new ODataProvider<Applicant>(
      this.axios,
      new ApplicantQueryBuilder(openCallId)
    );
  }

  householdMembersActions(memberId: string, hasSession: boolean, filter?: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberActionsQueryBuilder(memberId, hasSession, filter)
    );
  }
  sessionSubmissions(sessionId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new SessionRequestSubmissionsQueryBuilder(sessionId)
    );
  }

  mrrSubmission(entity?: string, projectId?: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new MrrSubmissionQueryBuilder(entity, projectId)
    );
  }

  householdMembersFilters(filter: string): ODataProvider<HouseholdMember> {
    return new ODataProvider<HouseholdMember>(
      this.axios,
      new HouseholdMemberQueryBuilder(filter)
    );
  }

  prescreeningMembersFilters(filter: string): ODataProvider<Prescreening> {
    return new ODataProvider<Prescreening>(
      this.axios,
      new PrescreeningQueryBuilder(filter)
    );
  }

  referralMembersFilters(filter: string): ODataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ReferralQueryBuilder(filter)
    );
  }

  householdMembersActionSessions(actionId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberActionSessionsQueryBuilder(actionId)
    );
  }

  householdMembersReferrals(memberId: string): DataProvider<ActionType> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberReferralsQueryBuilder(memberId)
    );
  }

  householdMembersRequests(memberId: string): DataProvider<ActionType> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberRequestsQueryBuilder(memberId)
    );
  }
  householdMemberIntakeFormSubmissions(
    memberId: string
  ): DataProvider<ActionType> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberIntakeFormSubmissionsQueryBuilder(memberId)
    );
  }
  householdMemberNotes(
    memberId: string
  ): DataProvider<ActionType> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberNotesQueryBuilder(memberId)
    );
  }  
  householdMemberSubmissions(memberId: string): DataProvider<ActionType> {
    return new ODataProvider<any>(
      this.axios,
      new HouseholdMemberSubmissionsQueryBuilder(memberId)
    );
  }
  programmeDonations(programId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProgrammeDonationsQueryBuilder(programId)
    );
  }

  programmeMeetings(pId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new NestedMeetingsQueryBuilder("Programmes", pId)
    );
  }
  projectMeetings(pId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new NestedMeetingsQueryBuilder("Projects", pId)
    );
  }

  projectManagerUsers(filter?: any): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProjectManagerUsersQueryBuilder(filter)
    );
  }

  getOpenCallFiles(openCallId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new OpenCallFilesQueryBuilder(openCallId)
    );
  }

  getProgrammeRfFiles(programmeId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new RFFileQueryBuilder(programmeId)
    );
  }

  getProjectRfFiles(projectId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new RFFileQueryBuilder(null, projectId)
    );
  }

  getProjectPartners(projectId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProjectPartnersQueryBuilder(projectId)
    );
  }

  getProjectDeliverables(projectId: string, partners?: boolean): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new DeliverableQueryBuilder(projectId, partners)
    );
  }

  getProjectExtensions(projectId: string): DataProvider<any> {
    return new ODataProvider<any>(
      this.axios,
      new ProjectExtensionsQueryBuilder(projectId)
    );
  }

  getMembersByPermission(myCases: boolean): DataProvider<HouseholdMember> {
    return new ODataProvider<HouseholdMember>(
      this.axios,
      new HouseHoldMembersByPermissionQueryBuilder(myCases)
    );
  }

  readonly mapForEnums: { [k: string]: ODataProvider<any> };
}
