import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectManagerUsersQueryBuilder implements IODataQueryBuilder {
  constructor(filter?: any) {
    this.defaultFilter = filter;
  }
  public readonly defaultFilter: string;
  public readonly entity = "Users";
  public readonly select = [];
  public readonly expand = ["UserGroup", "Programs"];
  public readonly expandOne = ["UserGroup", "Programs"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
