import IODataQueryBuilder from "../IODataQueryBuilder";

export default class NestedMeetingsQueryBuilder implements IODataQueryBuilder {
  constructor(entity?: string, id?: string) {
    if (entity && id) {
      this.entity = `${entity}(${id})/Meetings`;
    } else {
      this.entity = "Meetings";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Documentation"];
  public filter(val: string): string {
    const arr: string[] = [`contains(Description, '${val}')`];
    return arr.join(" or ");
  }
}
