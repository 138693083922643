export default [
    {
      name: "name",
      required: true,
      label: "Name",
      align: "left",
      sortable: true,
      field: (i: any) => i.name
    },
    {
      name: "options",
      required: true,
      label: "options",
      align: "left",
      sortable: true,
      field: (i: any) => (i.options ? i.options.length : "0")
    }
  ];