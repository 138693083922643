import { toArray } from "@/lib/answers";
import { Guid } from "guid-typescript";
import { HouseholdMember } from "./HouseholdMember";
import { IBaseModel } from "./IBaseModel";
import { Project } from "./Projects";
import { User } from "./Users";

export const QuestionnaireTypes = [
  "Default",
  "SmallForm",
  "Intake",
  "Prescreening",
  "AlwaysAvailable",
  "PersonalDevelopment",
  "Monitoring",
  "MRR"
];

export interface Questionnaire extends IBaseModel {
  name: string;
  createdAt: string;
  questions: Question[];
  isFinal: boolean;
  questionnaireType:
    | "Default"
    | "SmallForm"
    | "Intake"
    | "Prescreening"
    | "AlwaysAvailable"
    | "PersonalDevelopment"
    | "Monitoring"
    | "MRR";
  isActivePrescreening: boolean;
  isAdditionalBasicInformation: boolean;
  projects: Project[];
}

export interface Option extends IBaseModel {
  value: string;
  questionId?: string;
  priority?: number;
}

export interface BoundOptions extends IBaseModel {
  values: any[];
}
export enum QuestionType {
  Boolean,
  Freetext,
  Select,
  Multiselect,
  Linear,
  Number,
  Date,
  File
}
export interface Question extends IBaseModel {
  text: string;
  required: boolean;
  type:
    | "boolean"
    | "freetext"
    | "select"
    | "multiselect"
    | "linear"
    | "number"
    | "date"
    | "file"
    | "tuple";
  options?: Option[];
  boundTo: BoundOptions | null;
  showOtherOption?: boolean;
}
export interface Answer extends IBaseModel {
  questionId: string;
  responseText: string;
  responseBool: boolean;
  responseDate: Date;
  options?: Option[];
}
export interface QuestionnaireSubmission extends IBaseModel {
  beneficiary?: HouseholdMember | null;
  userId?: string;
  user?: User | null;
  questionnaire?: Questionnaire | null;
  submissionDate: Date;
  questionnaireId: string;
  answers: any[];
}

export function NewQuestionnaireSubmission(
  questionnaireId: string,
  answers: any[]
): QuestionnaireSubmission {
  const questionnaireSubmissionId = Guid.create().toString();
  const answersArray = !Array.isArray(answers) ? toArray(answers) : answers;
  for (const i of answersArray) {
    i.questionnaireSubmissionId = questionnaireSubmissionId;
  }
  const questionnaireSubmission: QuestionnaireSubmission = {
    id: questionnaireSubmissionId,
    beneficiary: null,
    user: null,
    questionnaire: null,
    submissionDate: new Date(),
    questionnaireId: questionnaireId,
    answers: answersArray
  };
  return questionnaireSubmission;
}

export interface Prescreening extends IBaseModel {
  prescreeningId: string;
  assignedToUserId?: string;
  assignedToUser: User | null;
  householdMemberId?: string;
  householdMember: HouseholdMember | null;
  questionnaireSubmission: QuestionnaireSubmission;
  prescreeningActionTakenId: string;
}
