import { AxiosInstance } from "axios";
import IDataService from "../IDataService";
import AuthService from "../AuthService";
import ODataProviders from "./ODataProviders";
import { ODataCrud } from "./ODataCrud";

export default class ODataService implements IDataService {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly auth: AuthService
  ) {
    this.axios.interceptors.request.use(async config => {
      const token = await this.auth.getAccessToken();
      config.headers.Authorization = "Bearer " + token;
      return config;
    });
    this.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (
          error != null &&
          error.response != null &&
          error.response.status === 401
        ) {
          auth.login();
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
  readonly providers = new ODataProviders(this.axios);

  readonly crud = new ODataCrud(this.axios);

  async downloadFile(filename: string): Promise<Blob> {
    const res = await this.axios.get("api/UploadedFiles/Download/" + filename, {
      responseType: "blob"
    });
    return res.data;
  }

  async downloadProjectCommunicationActivities(
    projectId: string
  ): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Projects(${projectId})/DownloadCommunicationActivities`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadProjectMonitoringActions(
    projectId: string
  ): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Projects(${projectId})/DownloadMonitoringActions`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadHouseholdMemberActions(
    memberId: string
  ): Promise<Blob> {
    const res = await this.axios.get(
      `odata/HouseholdMembers/${memberId}/DownloadMemberActions`,
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadEligibilityForm(applicantId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Applicants(${applicantId})/DownloadEligibilityForm`,
      {
        responseType: "blob"
      }
    );
    return res.data;
  }

  async downloadProjectMonitoringSession(
    projectId: string,
    sessionId: string
  ): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Projects(${projectId})/DownloadMonitoringSession(sId = ${sessionId})`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadQuestionnaireAnswers(submissionId: string): Promise<Blob> 
  {
    const res = await this.axios.get(
      `odata/QuestionnaireSubmissions(${submissionId})/DownloadAnswers`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadMrrQuestionnaire(
    projectId: string,
    mrrId: string
  ): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Projects(${projectId})/DownloadMrrQuestionnaire(mrrId = ${mrrId})`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadProjectMonitoringFindings(projectId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Projects(${projectId})/DownloadMonitoringFindings`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadProjectRiskAssessment(projectId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/RiskAssessments(${projectId})/DownloadCategories`
    );
    return this.b64toBlob(res.data.value);
  }

  b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async downloadHouseholdMember(id: string): Promise<Blob> {
    const res = await this.axios.get(`api/HouseholdMembers/${id}/Print`, {
      responseType: "blob"
    });
    return res.data;
  }

  async downloadPersonalDevelopmentPlan(memberid: string): Promise<Blob> {
    const res = await this.axios.get(
      `api/HouseholdMembers/${memberid}/PrintPersonalDevelopmentPlan`,
      {
        responseType: "blob"
      }
    );
    return res.data;
  }

  async printApplicantScores(applicantId: string): Promise<Blob> {
    const res = await this.axios.get(
      `api/Scores/PrintApplicantScores/${applicantId}`,
      {
        responseType: "blob"
      }
    );
    return res.data;
  }

  async downloadReports(reportFilters: any): Promise<Blob> {
    const res = await this.axios.post(
      `api/Reports/Reports/${reportFilters.ReportType}`,
      reportFilters
    );
    return this.b64toBlob(res.data);
  }

  async extract(name: string, filters?: any, id?: string): Promise<Blob> {
    if (filters) {
      const res = await this.axios.post(`api/${name}`, filters);
      return this.b64toBlob(res.data);
    } else if (id) {
      const res = await this.axios.get(`api/${name}/{${id}}`);
      return this.b64toBlob(res.data);
    } else {
      const res = await this.axios.get(`api/${name}/Extract`);
      return this.b64toBlob(res.data);
    }
  }

  async extraction(name: string, filters?: any, id?: string): Promise<Blob> {
    if (filters) {
      const res = await this.axios.post(`api/${name}/Extract`, filters);
      return this.b64toBlob(res.data);
    } else if (id) {
      const res = await this.axios.get(`api/${name}/Extract/{${id}}`);
      return this.b64toBlob(res.data);
    } else {
      const res = await this.axios.get(`api/${name}/Extract`);
      return this.b64toBlob(res.data);
    }
  }

  async printFinalScores(openCallId: string): Promise<Blob> {
    const res = await this.axios.get(
      `api/Scores/PrintApplicantsFinalScores/${openCallId}`,
      {
        responseType: "blob"
      }
    );
    return res.data;
  }

  async downloadConsentForm(id: string): Promise<Blob> {
    const res = await this.axios.get(`api/IntakeFormSubmissions/Print/${id}`, {
      responseType: "blob"
    });
    return res.data;
  }

  public async sendUserActivationEmail(id: string): Promise<any> {
    const res = await this.axios.post(`api/Users/${id}/SendConfirmationEmail`);
    return res.data;
  }

  public async uploadCommunicationActivities(
    file: File,
    projectId: string
  ): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await this.axios.post(
        `api/CommunicationActivities/Import/${projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  public async uploadMonitoringFindings(
    file: File,
    projectId: string
  ): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await this.axios.post(
        `api/MonitoringFindings/Import/${projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  async downloadIndicators(projectId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Indicators(${projectId})/DownloadIndicators`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadProjectSubmission(intakeId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/IntakeFormSubmissions(${intakeId})/DownloadProjectSubmission`
    );
    return this.b64toBlob(res.data.value);
  }

  public async uploadIndicators(file: File, projectId: string): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await this.axios.post(`api/Indicators/Import/${projectId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  async downloadIndicatorsRF(projectId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Indicators(${projectId})/DownloadIndicatorsRF`
    );
    return this.b64toBlob(res.data.value);
  }

  async downloadOpenCallResults(openCallId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/OpenCalls(${openCallId})/OpenCallResults`
    );
    return this.b64toBlob(res.data.value);
  }

  public async uploadIndicatorsRF(file: any, projectId: string, data?: any): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      if (data) {
        for (const item of Object.keys(data)) {
          formData.set(item, data[item]);
        }
      }
      await this.axios.post(`api/Indicators/ImportRF/${projectId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  async downloadApplicantScores(applicantId: string): Promise<Blob> {
    const res = await this.axios.get(
      `odata/Applicants(${applicantId})/DownloadApplicantScores`
    );
    return this.b64toBlob(res.data.value);
  }
  public async uploadRiskAssessment(
    file: File,
    projectId: string
  ): Promise<any> {
    try {
      const formData = new FormData();
      
      formData.append("file", file);
      await this.axios.post(
        `api/RiskAssessments/Import/${projectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  public async uploadApplicantScores(
    file: File,
    opencallId: string
  ): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await this.axios.post(`api/Applicants/Import/${opencallId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (e) {
      if (e.response && e.response.data) {
        throw e.response.data.error;
      }
      throw e;
    }
  }

  public async resetRfFiles(programmeId: string): Promise<any> {
    await this.axios.post(`api/RFFiles/Reset/${programmeId}`);
  }

  public async deleteFile(fileId: string): Promise<any> {
    await this.axios.delete(`api/UploadedFiles/Delete/${fileId}`);
  }

  public async getMemberNotes(memberId: string): Promise<any> {
    return await this.axios.get(`api/HouseholdMembers/Notes/${memberId}`);
  }

  public async getScheduledAppointments(filters: any): Promise<any> {
    return await this.axios.post(
      `api/RequestSubmissions/GetScheduledAppointments`, filters
    );
  }

  public async getApiVersion(): Promise<any> {
    const res = await this.axios.get("/api");
    return res.data;
  }

  public async getReport(name: string, filters: any): Promise<any> {
    const res = await this.axios.post(`/api/reports/${name}`, filters);
    return res.data;
  }
}
