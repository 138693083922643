<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "LayoutDefault",

  components: {},
  metaInfo: {
    title: "HumanRights360",
  },
  data() {
    return {
      leftDrawerOpen: false
    };
  }
};
</script>
<style>
.q-card__actions .q-btn__wrapper{
  padding: 4px 16px !important;
}
</style>
