import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ActionTypeQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "ActionTypes";
  public readonly select = [];
  public readonly expand = ["ActionActionTypes"];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
