import { IBaseModel } from "@/data/models/IBaseModel";
import { AxiosInstance } from "axios";
import ICrudClient from "../ICrudClient";

export default class ApiCrudClient<T extends IBaseModel>
  implements ICrudClient<T> {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly baseUrl: string
  ) {}
  async postAsync(path: string, data: any): Promise<any> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const res = await this.axios.post(this.baseUrl + path, data, {
      headers
    });
    return res.data;
  }
  async addAsync(items: T[]): Promise<T[]> {
    try {
      const headers = { Accept: "application/json" };
      const jitems = JSON.parse(JSON.stringify(items));
      const res = await this.axios.post(this.baseUrl, jitems, { headers });
      return res.data;
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 403:
            throw "Access Denied";
          default:
            throw "The operation failed. Make sure that you did not delete anything that is referenced by other entities.";
        }
      }
      throw e;
    }
  }
  async saveAsync(item: T): Promise<T> {
    try {
      const headers = { Accept: "application/json" };
      const url = `${this.baseUrl}`;
      const res = await this.axios.put(url, item, { headers });
      return res.data;
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 403:
            throw "Access Denied";
          default:
            throw "The operation failed. Make sure that you did not delete anything that is referenced by other entities.";
        }
      }
      throw e;
    }
  }
  async deleteAsync(items: string[]): Promise<any> {
    try {
      const headers = { Accept: "application/json" };
      const res = await this.axios.delete(this.baseUrl, {
        headers: headers,
        data: items
      });
      return res.data.$values;
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 403:
            throw "Access Denied";
          default:
            throw "The operation failed. Make sure that you did not delete anything that is referenced by other entities.";
        }
      }
      throw e;
    }
  }
}
