import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProgrammePeriodsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id?: string) {
    if (id) {
      this.entity = `Programmes(${id})/ProgrammePeriods`;
    }
    else {
      this.entity = `ProgrammePeriods`;
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}