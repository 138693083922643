import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberActionsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string, nonSession: boolean, filter?: string) {
    if (nonSession) {
      this.defaultFilter = "ActionActionType/ActionType/HasSessions eq false";
    }
    if (filter) {
      this.defaultFilter = nonSession ? ` and ${filter}` : filter;
    }
    this.entity = `HouseholdMembers(${id})/Actions`;
  }
  public readonly defaultFilter?: string;
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "Project",
    "ActionActionType($expand=Action($expand=purposes),ActionType),Sessions($count=true;$top=0)",
    "Companies"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
