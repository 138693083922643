export default [
  {
    name: "email",
    required: true,
    label: "Email",
    align: "left",
    sortable: true,
    field: (i: any) => i.email
  },
  {
    name: "firstName",
    required: true,
    label: "First Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.firstName
  },
  {
    name: "lastName",
    required: true,
    label: "Last Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.lastName
  },
  {
    name: "Usergroup/name",
    required: true,
    label: "Group",
    align: "left",
    sortable: true,
    field: (i: any) => (i.userGroup ? i.userGroup.name : "")
  },
  {
    name: "EmailConfirmed",
    required: true,
    label: "Confirmed",
    align: "left",
    sortable: true,
    field: (i: any) => i.emailConfirmed
  }
];
