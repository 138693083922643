import IODataQueryBuilder from "../IODataQueryBuilder";

export default class PrescreeningQueryBuilder implements IODataQueryBuilder {
  constructor(filter?: any) {
    this.defaultFilter = filter;
  }
  public readonly defaultFilter: string;
  public readonly entity = "Prescreenings";
  public readonly select = [];
  public readonly expand = [
    "HouseholdMember,PrescreeningActionTaken,AssignedToUser,QuestionnaireSubmission($expand=User,Questionnaire($expand=Questions($top=0;$count=true)),Answers($top=0;$count=true))"
  ];
  public readonly expandOne = [
    "HouseholdMember,PrescreeningActionTaken,AssignedToUser,QuestionnaireSubmission($expand=User,Questionnaire($expand=Questions($orderby=Priority asc;$expand=Options($orderby=Priority),BoundTo($expand=Options,BoundQuestion))),Answers($expand=Options,File,Tuples))"
  ];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(PrescreeningId, '${val}')`,
      `contains(QuestionnaireSubmission/User/FirstName, '${val}')`,
      `contains(QuestionnaireSubmission/User/LastName, '${val}')`,
      `contains(QuestionnaireSubmission/User/Email, '${val}')`,
      `contains(cast(QuestionnaireSubmission/SubmissionDate, Edm.String), '${val}')`
    ];
    return arr.join(" or ");
  }
}
