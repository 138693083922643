import DataProvider, { FilterOp, ColumnFilter } from "../DataProvider";
import ItemsResult from "../ItemsResult";
import { AxiosInstance } from "axios";
import IODataQueryBuilder from "./IODataQueryBuilder";

export default class ODataProvider<T> implements DataProvider<T> {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly builder: IODataQueryBuilder,
    private readonly customFilters?: string
  ) {}
  fetchItemsAsync(args?: {
    top?: number | undefined;
    skip?: number | undefined;
    orderby?: string | undefined;
    descending?: boolean | undefined;
    filter?: string | undefined;
    select?: string | undefined;
    columnFilters?: ColumnFilter[];
    filterExpression?: string;
  }): Promise<ItemsResult<T>> {
    const parts: { [key: string]: string } = {};
    if (args) {
      if (args.top) {
        parts["$top"] = args.top.toString();
      }
      if (args.skip) {
        parts["$skip"] = args.skip.toString();
      }
      if (args.orderby) {
        parts["$orderby"] = `${args.orderby} ${
          args.descending ? "desc" : "asc"
        }`;
      }
      if (args.filter) {
        const filter = this.builder.filter(
          encodeURIComponent(args.filter.replace(/'/g, "''"))
        );
        if (filter) {
          parts["$filter"] = filter;
        }
      }
      if (args.select) {
        parts["$select"] = args.select;
      }
      if (args.columnFilters) {
        parts["$filter"] =
          (parts["$filter"] ? `(${parts["$filter"]}) and ` : "") +
          args.columnFilters
            .map(k => `${k.column} ${this.getODataOp(k.op)} ${k.value}`)
            .join(" and ");
      }
      if (args.filterExpression) {
        parts["$filter"] = args.filterExpression;
      }
    }
    if (this.customFilters) {
      if (parts["$filter"]) {
        parts["$filter"] = `(${parts["$fitler"]}) and (${this.customFilters})`;
      } else {
        parts["$filter"] = this.customFilters;
      }
    }
    if (this.builder.defaultFilter) {
      if (parts["$filter"]) {
        parts[
          "$filter"
        ] = `(${parts["$fitler"]}) and (${this.builder.defaultFilter})`;
      } else {
        parts["$filter"] = this.builder.defaultFilter;
      }
    }

    if (this.builder.select.length > 0) {
      if (!parts["$select"]) {
        parts["$select"] = this.builder.select.join(",");
      }
    }
    if (this.builder.expand.length > 0) {
      parts["$expand"] = this.builder.expand.join(",");
    }

    parts["$count"] = "true";

    const url = `odata/${this.builder.entity}?${Object.keys(parts)
      .map((k: string) => k + "=" + parts[k])
      .join("&")}`;
    return this.getResultAsync(url);
  }

  private getODataOp(op: FilterOp) {
    switch (op) {
      case "NotEquals":
        return "ne";
      default:
        return "eq";
    }
  }

  fetchItemAsync(id: string): Promise<T> {
    const parts: { [key: string]: string } = {};
    if (this.builder.select.length > 0) {
      parts["$select"] = this.builder.select.join(",");
    }
    if (this.builder.expand.length > 0) {
      parts["$expand"] = this.builder.expand.join(",");
    }
    if (this.builder.expandOne && this.builder.expandOne.length > 0) {
      parts["$expand"] = this.builder.expandOne.join(",");
    }
    const url = `odata/${this.builder.entity}(${id})?${Object.keys(parts)
      .map((k: string) => k + "=" + parts[k])
      .join("&")}`;
    return this.getSingleAsync(url);
  }

  protected async getResultAsync(url: string): Promise<ItemsResult<T>> {
    const headers = { Accept: "application/json" };
    let res = await this.axios.get(url, { headers });
    const length = res.data["@odata.count"];
    let items: any[] = res.data.value;
    let next = res.data["@odata.nextLink"];
    while (next) {
      const url2 = decodeURIComponent(next);
      res = await this.axios.get(url2, { headers });
      next = res.data["@odata.nextLink"];
      const subitems: any[] = res.data.value;
      items = items.concat(subitems);
    }
    return new ItemsResult(items, length);
  }

  protected async getSingleAsync(url: string): Promise<any> {
    const headers = { Accept: "application/json" };
    const res = await this.axios.get(url, { headers });
    const item: any = res.data;
    return item;
  }
}
