import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectDeliverableQueryBuilder implements IODataQueryBuilder {
  constructor(projectId?: any, partners?: boolean) {
    if (partners){
      this.defaultFilter = "ProjectPartner eq null";
    }
    if (projectId) {
      this.entity = `Projects(${projectId})/Deliverables`;
    } else {
      this.entity = "Deliverables";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Status", "ProjectPartner", "ProjectExtension", "Document"];
  public readonly defaultFilter?: string;
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}