import IDataService from "@/lib/IDataService";
import crudRouteGenerator from "./crudRouteGenerator";
import EnumsColumns from "@/data/columns/Enums";
import lazyLoadView from "./lazyLoadView";
import { Route, RouteConfig } from "vue-router";
import AuthService from "@/lib/AuthService";

function enumRouteGenerator(
  service: IDataService,
  auth: AuthService,
  path: string,
  providerKey: string,
  listTile: string,
  name: string,
  formComponent?: Promise<{
    functional: boolean;
    render(h: any, { data, children }: any): any;
  }>
): RouteConfig {
  return crudRouteGenerator({
    viewPermission: auth.perm(p => false),
    editPermission: auth.perm(p => false),
    addPermission: auth.perm(p => false),
    deletePermission: auth.perm(p => false),
    path: path,
    contextCellName: "value",
    columns: EnumsColumns,
    provider: service.providers.mapForEnums[providerKey],
    crud: service.crud.mapForEnums[providerKey],
    listTitle: listTile,
    formComponent:
      formComponent || lazyLoadView(import("@/components/forms/EnumForm.vue")),
    addRouteName: providerKey + "Form",
    listRouteName: providerKey + "List",
    props: (r: Route) => {
      return {
        name: name,
        sortBy: "Value",
        routeAfterSave: (item: any) => ({
          name: providerKey + "Form",
          params: { id: item.id }
        }),
        routeAfterSaveAndClose: (item: any) => ({
          name: providerKey + "List"
        })
      };
    }
  });
}
export default function getEnumRoutes(
  service: IDataService,
  auth: AuthService
) {
  return [
    enumRouteGenerator(
      service,
      auth,
      "/age-ranges",
      "AgeRanges",
      "Age Ranges",
      "Age Range"
    ),
    enumRouteGenerator(
      service,
      auth,
      "/activity-types",
      "ActivityTypes",
      "Activity Types",
      "Activity Type",
      lazyLoadView(import("@/components/forms/ActivityTypeForm.vue"))
    ),
    enumRouteGenerator(
      service,
      auth,
      "/citizenships",
      "Citizenships",
      "Citizenships",
      "Citizenship"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/european-countries",
      "EuropeanCountries",
      "European Countries",
      "European Country"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/genders",
      "Genders",
      "Genders",
      "Gender"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/housing-types",
      "HousingTypes",
      "Housing Types",
      "Housing Type"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/communication-activity-values",
      "CommunicationActivityValues",
      "Communication Activity Values",
      "Communication Activity Value"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/deliverable-statuses",
      "DeliverableStatuses",
      "Deliverable Statuses",
      "Deliverable Status"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/household-types",
      "HouseholdTypes",
      "Household Types",
      "Household Type"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/household-member-roles",
      "HouseholdMemberRoles",
      "Household Member Roles",
      "Household Member Role"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/languages",
      "Languages",
      "Languages",
      "Language"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/relatedToRisks",
      "RelatedToRisks",
      "Related To Risks",
      "Related To Risk"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/language-skills",
      "LanguageSkills",
      "Language Skills",
      "Language Skill"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/legal-statuses",
      "LegalStatuses",
      "Legal Statuses",
      "Legal Status"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/locations",
      "Locations",
      "Locations",
      "Location"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/nationalities",
      "Nationalities",
      "Nationalities",
      "Nationality"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/organizations",
      "Organizations",
      "Organizations",
      "Organization"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/organizers",
      "Organizers",
      "Organizers",
      "Organizer"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/public-authorities",
      "PublicAuthorities",
      "Public Authorities",
      "Public Authority"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/reach-HR360",
      "ReachHR360",
      "Reach HR360",
      "Reach HR360"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/role-in-partnership",
      "RoleInPartnerships",
      "Roles in Partnerships",
      "Role in Partnerships"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/thematic-categories",
      "ThematicCategories",
      "Thematic Categories",
      "Thematic Category"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/vulnerabilities",
      "Vulnerabilities",
      "Vulnerabilities",
      "Vulnerability"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/requests",
      "Requests",
      "Requests",
      "Request"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/target-types",
      "TargetTypes",
      "Target Types",
      "Target Type"
    ),

    enumRouteGenerator(
      service,
      auth,
      "/purposes",
      "Purposes",
      "Purposes",
      "Purpose",
      lazyLoadView(import("@/components/forms/PurposeForm.vue"))
    ),

    enumRouteGenerator(
      service,
      auth,
      "/session-requests",
      "SessionRequests",
      "Session Requests",
      "Session Request",
      lazyLoadView(import("@/components/forms/SessionRequestForm.vue"))
    ),

    enumRouteGenerator(
      service,
      auth,
      "/next-steps",
      "NextSteps",
      "Next Steps",
      "Next Step",
      lazyLoadView(import("@/components/forms/NextStepForm.vue"))
    ),

    enumRouteGenerator(
      service,
      auth,
      "/employment-sectors",
      "EmploymentSectors",
      "Employment Sectors",
      "Employment Sector"
    ),
  ];
}
