import { Project } from "./Projects";
import { Location } from "./Locations";
import { Currency } from "./Currencies";
import { Indicator } from "./Indicators";
import { IBaseModel } from "./IBaseModel";
import { ResultFramework } from "./ResultFramework";

export enum ProgramType {
  Regranting,
  Implementation
}
const ProgramTypes: string[] = [];
for (const enumMember in ProgramType) {
  const isValueProperty = parseInt(enumMember, 10) >= 0;
  if (isValueProperty) {
    ProgramTypes.push(ProgramType[enumMember]);
  }
}

export { ProgramTypes };
export interface Donor extends IBaseModel {
  name: string;
}

export interface Programme extends IBaseModel {
  title: string;
  type: ProgramType | null;
  locations: Location[] | null;
  startDate: string;
  endDate: string;
  projects: Project[];
  fundAmount: number;
  currency: Currency | null;
  hasResultFramework: boolean;
  indicators: Indicator[];
  resultFramework: ResultFramework | null;
  donations: any[];
  agreement: any;
}
