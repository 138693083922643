import { Questionnaire } from "../models/Questionnaires";

export default [
  {
    name: "title",
    required: true,
    label: "Title",
    align: "left",
    sortable: true,
    field: (val: any) => (val ? val.title : "")
  },
  {
    name: "donors",
    required: true,
    label: "Donors",
    align: "left",
    sortable: true,
    field: (val: any) =>
      val && val.donors ? val.donors.map((d: any) => d.name).join(", ") : ""
  },
  {
    name: "projects",
    required: true,
    label: "Projects",
    align: "left",
    sortable: true,
    field: (val: any) =>
      val && val.projects
        ? val.projects.map((p: any) => p.title).join(", ")
        : ""
  },
  {
    name: "communicationType",
    required: true,
    label: "Communication Type",
    align: "left",
    sortable: true,
    field: (val: any) =>
      val && val.communicationType ? val.communicationType.name : ""
  },
  {
    name: "startDate",
    required: true,
    label: "Start Date",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.startDate ? new Date(Date.parse(i.startDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "End Date",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.endDate ? new Date(Date.parse(i.endDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  }
];
