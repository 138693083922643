import IODataQueryBuilder from "../IODataQueryBuilder";

export default class CriterionQueryBuilder implements IODataQueryBuilder {
  constructor(id = '') {
    if(id){
      this.entity = `OpenCalls(${id})/Criteria`;
    } else {
      this.entity = '/Criteria'
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["ParentCriterion", "ChildCriteria"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
