import IODataQueryBuilder from "../IODataQueryBuilder";

export default class IndicatorValueDetailsQueryBuilder implements IODataQueryBuilder {
  constructor(id?: string) {
    if (id) {
      this.entity = `IndicatorValue(${id})IndicatorValueDetails`;
    } else {
      this.entity = "IndicatorValueDetails";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  
  public readonly expand = ["IndicatorDetails($expand=IndicatorValuesDetails)"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
