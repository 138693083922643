import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProjectIndicatorsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `Projects(${id})/Indicators`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "SourceOfVerification",
    "FrequencyOfReporting",
    "ParentIndicator",
    "ResultFrameworkBreakDowns($expand=Options)",
    "IndicatorValues($expand=Period,BreakdownOption($expand=ResultFrameworkBreakdown))"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
