import IODataQueryBuilder from "../IODataQueryBuilder";

export default class RequestSubmissionsQueryBuilder
  implements IODataQueryBuilder {
  constructor() {
    this.entity = `RequestSubmissions`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "Request",
    "NextStep",
    "RequestQuestionnaireSubmission($expand=Questionnaire($expand=Questions($expand=Options,BoundTo($expand=Options,BoundQuestion))),Answers($expand=Options,Tuples,File))",
    "NextStepQuestionnaireSubmission($expand=Questionnaire($expand=Questions($expand=Options,BoundTo($expand=Options,BoundQuestion))),Answers($expand=Options,Tuples,File))",
    "Session($expand=HouseholdMemberAction($expand=ActionActionType($expand=Action)))"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
