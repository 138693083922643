import IODataQueryBuilder from "../IODataQueryBuilder";

export default class RiskCategoryRiskQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `RiskCategories(${id})/Risks`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Category"];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(Description, '${val}')`,
      `contains(RelatedTo, '${val}')`
    ];
    return arr.join(" or ");
  }
}
