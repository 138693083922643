export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => {
      const name = i.name
        .split("-")
        .slice(5)
        .join("-");
      if (name.length < 42) {
        return name;
      }
      return (
        name.slice(0, 20) + ".." + name.slice(name.length - 20, name.length)
      );
    }
  },
  {
    name: "type",
    required: true,
    label: "Type",
    align: "left",
    sortable: false,
    field: (i: any) => {
      return i.consent != null ? "Consent Form" : "Answer";
    }
  }

  /*
  {
    name: "user",
    required: true,
    label: "User",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.user ? `${i.user.firstName} ${i.user.lastName}` : ""
  },
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.date
  }*/
];
