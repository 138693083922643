export default [
  {
    name: "value",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.value
  },
  {
    name: "symbol",
    required: true,
    label: "Symbol",
    align: "left",
    sortable: true,
    field: (i: any) => i.symbol
  }
];
