import IODataQueryBuilder from "../IODataQueryBuilder";

export default class QuestionnaireSubmissionQueryBuilder
  implements IODataQueryBuilder {
  public readonly entity = "QuestionnaireSubmissions";
  public readonly select = [];
  public readonly expand = ["Prescreening", "Beneficiary", "Questionnaire"];
  public readonly expandOne = [
    "Prescreening,UploadedFile,Beneficiary,User,Questionnaire($expand=Questions($orderby=Priority asc;$expand=Options($orderby=Priority),BoundTo($expand=Options,BoundQuestion))),Answers($expand=Options,Tuples,File)"
  ];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(User/FirstName, '${val}')`,
      `contains(User/LastName, '${val}')`,
      `contains(User/Email, '${val}')`,
      `contains(cast(SubmissionDate, Edm.String), '${val}')`
    ];
    return arr.join(" or ");
  }
}
