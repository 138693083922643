import IODataQueryBuilder from "../IODataQueryBuilder";

export default class CompanyQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Companies";
  public readonly select = [];
  public readonly expand = ["EmploymentSectors"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}