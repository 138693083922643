import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProgrammeQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Programmes";
  public readonly select = [];
  public readonly expand = [
    "Beneficiaries",
    "Projects",
    "Locations",
    "Currency",
    "Donations"
  ];
  public readonly expandOne = [
    "Beneficiaries",
    "Projects($expand=ProjectExtensions($expand=Donor,Currency))",
    "Locations",
    "Currency",
    "ResultFramework",
    "Donations($expand=Donor,Currency)",
    "Agreement"
  ];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(Title, '${val}')`,
      `contains(cast(StartDate, Edm.String), '${val}')`,
      `contains(cast(EndDate, Edm.String), '${val}')`,
      `Locations/any(location: contains(location/Value, '${val}'))`
    ];
    return arr.join(" or ");
  }
}
