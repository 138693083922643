import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ChildCriterionQueryBuilder implements IODataQueryBuilder {
  constructor(id = '', parentId: string) {
    if(id){
      this.entity = `OpenCalls(${id})/Criteria`;
    } else {
      this.entity = '/Criteria'
    }
    this.defaultFilter = `ParentCriterion/Id eq ${parentId}`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["ParentCriterion", "ChildCriteria"];
  public readonly defaultFilter: string;
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}