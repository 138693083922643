import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ActionQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Actions";
  public readonly select = [];
  public readonly expand = [
    "ActionActionTypes($expand=Action,ActionType,questionnaire),Purposes"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
