import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProgrammeDonationsQueryBuilder
  implements IODataQueryBuilder {
  constructor(programId: string) {
    this.entity = `Programmes(${programId})/Donations`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["Donor", "Currency"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
