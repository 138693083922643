import { Action } from "@/data/models/Actions";
import { ActionType } from "@/data/models/ActionTypes";
import { Activity } from "@/data/models/Activity";
import { ActivityRecord } from "@/data/models/ActivityRecord";
import { Currency } from "@/data/models/Currencies";
import { Gender } from "@/data/models/Genders";
import { AgeRanges } from "@/data/models/AgeRanges";
import { Citizenships } from "@/data/models/Citizenships";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import { HouseholdMemberRole } from "@/data/models/HouseholdMemberRoles";
import { HousingType } from "@/data/models/HousingTypes";
import { IntakeFormSubmission } from "@/data/models/IntakeFormSubmission";
import { LegalStatus } from "@/data/models/LegalStatus";
import { Nationality } from "@/data/models/Nationalities";
import { Location } from "@/data/models/Locations";
import {
  Applicant,
  Criterion,
  FinalScore,
  OpenCall,
  Score
} from "@/data/models/OpenCall";
import { Donor, Programme } from "@/data/models/Programs";
import { Project } from "@/data/models/Projects";
import {
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import {
  CommunicationType,
  StrategicCommunication
} from "@/data/models/StrategicCommunication";
import { UploadedFile } from "@/data/models/UploadedFiles";
import { AxiosInstance } from "axios";
import ICrudClient from "../ICrudClient";
import ApiCrudClient from "./ApiCrudClient";
import { ConsentForm } from "@/data/models/ConsentForm";
import { ActivityType } from "@/data/models/ActivityType";
import { ResultFrameworkBreakdown } from "@/data/models/ResultFrameworkBreakDown";
import { EmploymentSector } from "@/data/models/EmploymentSector";
import { Company } from "@/data/models/Company";
import { RelatedToRisk } from "@/data/models/RelatedToRisks";

class EnumActionClientMap {
  constructor(private readonly axios: AxiosInstance) {}
  private readonly dict: { [k: string]: ICrudClient<any> } = {};
  get(a: any, b: any, c: any) {
    if (!this.dict[b]) {
      this.dict[b] = new ApiCrudClient<any>(this.axios, `api/${b}/`);
    }
    return this.dict[b];
  }
}

export class ODataCrud {
  constructor(private readonly axios: AxiosInstance) {
    this.strategicCommunications = new ApiCrudClient<StrategicCommunication>(
      this.axios,
      "api/StrategicCommunications/"
    );

    this.openCalls = new ApiCrudClient<OpenCall>(this.axios, "api/OpenCalls/");

    this.applicants = new ApiCrudClient<Applicant>(
      this.axios,
      "api/Applicants/"
    );

    this.consentForms = new ApiCrudClient<ConsentForm>(
      this.axios,
      "api/consentForms/"
    );

    this.finalScores = new ApiCrudClient<FinalScore>(
      this.axios,
      "api/FinalScores/"
    );

    this.scores = new ApiCrudClient<Score>(this.axios, "api/Scores/");

    this.criteria = new ApiCrudClient<Criterion>(this.axios, "api/Criteria/");

    this.projects = new ApiCrudClient<Project>(this.axios, "api/Projects/");

    this.projectPartners = new ApiCrudClient<any>(this.axios, "api/ProjectPartners/");
    this.projectDeliverables = new ApiCrudClient<any>(this.axios, "api/Deliverables/");
    this.projectExtensions = new ApiCrudClient<any>(this.axios, "api/ProjectExtensions/");

    this.householdMemberRoles = new ApiCrudClient<HouseholdMemberRole>(
      this.axios,
      "HouseholdMemberRoles/"
    );

    this.housingTypes = new ApiCrudClient<HousingType>(
      this.axios,
      "api/HousingTypes/"
    );

    this.legalStatuses = new ApiCrudClient<LegalStatus>(
      this.axios,
      "api/LegalStatuses/"
    );

    this.nationalities = new ApiCrudClient<Nationality>(
      this.axios,
      "api/Nationalities/"
    );

    this.employmentSectors = new ApiCrudClient<EmploymentSector>(
      this.axios,
      "api/EmploymentSectors/"
    );

    this.companies = new ApiCrudClient<Company>(this.axios, "api/Companies/");

    this.activityRecords = new ApiCrudClient<ActivityRecord>(
      this.axios,
      "api/ActivityRecords/"
    );

    this.activities = new ApiCrudClient<Activity>(
      this.axios,
      "api/Activities/"
    );

    this.intakeFormSubmissions = new ApiCrudClient<IntakeFormSubmission>(
      this.axios,
      "api/IntakeFormSubmissions/"
    );

    this.questionnaires = new ApiCrudClient<Questionnaire>(
      this.axios,
      "api/Questionnaires/"
    );

    this.communicationActivityValues = new ApiCrudClient<Questionnaire>(
      this.axios,
      "api/CommunicationActivityValues/"
    );

    this.communicationActivities = new ApiCrudClient<Questionnaire>(
      this.axios,
      "api/CommunicationActivities/"
    );

    this.programs = new ApiCrudClient<Programme>(this.axios, "api/Programmes/");

    this.risks = new ApiCrudClient<Prescreening>(this.axios, "api/Risks/");

    this.riskCategories = new ApiCrudClient<any>(
      this.axios,
      "api/RiskCategories/"
    );

    this.prescreenings = new ApiCrudClient<Prescreening>(
      this.axios,
      "api/Prescreenings/"
    );

    this.locations = new ApiCrudClient<Location>(this.axios, "api/Locations/");
    this.relatedToRisks = new ApiCrudClient<RelatedToRisk>(this.axios, "api/RelatedToRisks/");

    this.currencies = new ApiCrudClient<Currency>(
      this.axios,
      "api/Currencies/"
    );

    this.genders = new ApiCrudClient<Gender>(this.axios, "api/Genders/");

    this.Citizenships = new ApiCrudClient<Citizenships>(
      this.axios,
      "api/Citizenships/"
    );

    this.AgeRanges = new ApiCrudClient<AgeRanges>(this.axios, "api/AgeRanges/");

    this.sessionSubmissions = new ApiCrudClient<any>(
      this.axios,
      "api/RequestSubmissions/"
    );

    this.householdMembers = new ApiCrudClient<HouseholdMember>(
      this.axios,
      "api/HouseholdMembers/"
    );

    this.users = new ApiCrudClient<any>(this.axios, "api/Users/");

    this.sessions = new ApiCrudClient<any>(this.axios, "api/Sessions/");

    this.uploadedFiles = new ApiCrudClient<UploadedFile>(
      this.axios,
      "api/UploadedFiles/"
    );

    this.questionnaireSubmissions = new ApiCrudClient<QuestionnaireSubmission>(
      this.axios,
      "api/QuestionnaireSubmissions/"
    );

    this.mrrSubmissions = new ApiCrudClient<any>(
      this.axios,
      "api/MrrSubmissions/"
    );

    this.actionTypes = new ApiCrudClient<ActionType>(
      this.axios,
      "api/ActionTypes/"
    );

    this.actions = new ApiCrudClient<Action>(this.axios, "api/Actions/");

    this.userGroups = new ApiCrudClient<Action>(this.axios, "api/UserGroups/");

    this.actionActionTypes = new ApiCrudClient<Action>(
      this.axios,
      "api/ActionActionTypes/"
    );

    this.householdMembersActions = new ApiCrudClient<Action>(
      this.axios,
      "api/HouseholdMemberActions/"
    );

    this.purposes = new ApiCrudClient<Action>(this.axios, "api/Purposes/");

    this.activityTypes = new ApiCrudClient<ActivityType>(
      this.axios,
      "api/ActivityTypeTypes/"
    );

    this.sessionRequests = new ApiCrudClient<Action>(
      this.axios,
      "api/SessionRequests/"
    );

    this.nextSteps = new ApiCrudClient<Action>(this.axios, "api/NextSteps/");

    this.referrals = new ApiCrudClient<any>(this.axios, "api/Referrals/");

    this.donors = new ApiCrudClient<Donor>(this.axios, "api/Donors/");

    this.settings = new ApiCrudClient<Donor>(this.axios, "api/Settings/");

    this.communicationTypes = new ApiCrudClient<CommunicationType>(
      this.axios,
      "api/CommunicationTypes/"
    );

    this.resultFrameworkBreakdowns = new ApiCrudClient<
      ResultFrameworkBreakdown
    >(this.axios, "api/ResultFrameworkBreakdowns/");

    this.appeals = new ApiCrudClient<any>(this.axios, "api/Appeals/");
    this.monitoringFindings = new ApiCrudClient<any>(
      this.axios,
      "api/MonitoringFindings/"
    );
    this.monitoringSessions = new ApiCrudClient<any>(
      this.axios,
      "api/MonitoringSessions/"
    );
    this.partners = new ApiCrudClient<any>(this.axios, "api/Partners/");

    this.followUp = new ApiCrudClient<any>(this.axios, "api/FollowUp/");

    this.followUpSubmissions = new ApiCrudClient<any>(
      this.axios,
      "api/FollowUpSubmissions/"
    );

    this.programmePeriods = new ApiCrudClient<any>(
      this.axios,
      "api/ProgrammePeriods"
    );

    this.meetings = new ApiCrudClient<any>(this.axios, "api/Meetings/");

    this.indicators = new ApiCrudClient<any>(this.axios, "api/Indicators/");

    this.indicatorValues = new ApiCrudClient<any>(
      this.axios,
      "api/IndicatorValues/"
    );

    this.indicatorValueDetails = new ApiCrudClient<any>(
      this.axios,
      "api/IndicatorValuesDetails/"
    );

    this.regrantingProgrammeIndicators = new ApiCrudClient<any>(
      this.axios,
      "api/Projects/RegrantingIndicators"
    );

    this.projectCommunicationActivities = new ApiCrudClient<any>(
      this.axios,
      "api/CommunicationActivities/"
    );

    this.openCallFiles = new ApiCrudClient<any>(
      this.axios,
      "api/OpenCallFiles/"
    )

    this.rfFiles = new ApiCrudClient<any>(
      this.axios,
      "api/RFFiles/"
    )

    this.mapForEnums = new Proxy(this, new EnumActionClientMap(this.axios));
  }

  readonly projectCommunicationActivities: ICrudClient<any>;

  readonly strategicCommunications: ICrudClient<StrategicCommunication>;

  readonly openCalls: ICrudClient<OpenCall>;

  readonly applicants: ICrudClient<Applicant>;

  readonly consentForms: ICrudClient<ConsentForm>;

  readonly finalScores: ICrudClient<FinalScore>;

  readonly scores: ICrudClient<Score>;

  readonly criteria: ICrudClient<Criterion>;

  readonly projects: ICrudClient<Project>;
  readonly projectPartners: ICrudClient<any>;
  readonly projectDeliverables: ICrudClient<any>;
  readonly projectExtensions: ICrudClient<any>;

  readonly mapForEnums: { [k: string]: ICrudClient<any> };

  readonly householdMemberRoles: ICrudClient<HouseholdMemberRole>;

  readonly housingTypes: ICrudClient<HousingType>;

  readonly legalStatuses: ICrudClient<LegalStatus>;

  readonly nationalities: ICrudClient<Nationality>;
  readonly employmentSectors: ICrudClient<EmploymentSector>;
  readonly companies: ICrudClient<Company>;

  readonly activityRecords: ICrudClient<ActivityRecord>;

  readonly activities: ICrudClient<Activity>;

  readonly intakeFormSubmissions: ICrudClient<IntakeFormSubmission>;

  readonly questionnaires: ICrudClient<Questionnaire>;

  readonly communicationActivityValues: ICrudClient<any>;

  readonly communicationActivities: ICrudClient<any>;

  readonly programs: ICrudClient<Programme>;

  readonly risks: ICrudClient<any>;

  readonly riskCategories: ICrudClient<any>;

  readonly prescreenings: ICrudClient<Prescreening>;

  readonly locations: ICrudClient<Location>;
  readonly relatedToRisks: ICrudClient<RelatedToRisk>;

  readonly currencies: ICrudClient<Currency>;

  readonly genders: ICrudClient<Gender>;

  readonly Citizenships: ICrudClient<Citizenships>;

  readonly AgeRanges: ICrudClient<AgeRanges>;

  readonly sessionSubmissions: ICrudClient<any>;

  readonly householdMembers: ICrudClient<HouseholdMember>;

  readonly users: ICrudClient<any>;

  readonly sessions: ICrudClient<any>;

  readonly uploadedFiles: ICrudClient<UploadedFile>;

  readonly questionnaireSubmissions: ICrudClient<QuestionnaireSubmission>;
  readonly mrrSubmissions: ICrudClient<any>;

  readonly actionTypes: ICrudClient<ActionType>;

  readonly actions: ICrudClient<Action>;

  readonly userGroups: ICrudClient<Action>;

  readonly actionActionTypes: ICrudClient<any>;

  readonly householdMembersActions: ICrudClient<any>;

  readonly purposes: ICrudClient<any>;

  readonly activityTypes: ICrudClient<any>;

  readonly sessionRequests: ICrudClient<any>;

  readonly nextSteps: ICrudClient<any>;

  readonly referrals: ICrudClient<any>;

  readonly donors: ICrudClient<Donor>;

  readonly settings: ICrudClient<any>;

  readonly communicationTypes: ICrudClient<CommunicationType>;
  readonly resultFrameworkBreakdowns: ICrudClient<ResultFrameworkBreakdown>;

  readonly appeals: ICrudClient<any>;
  readonly monitoringFindings: ICrudClient<any>;
  readonly monitoringSessions: ICrudClient<any>;
  readonly partners: ICrudClient<any>;

  readonly followUp: ICrudClient<any>;

  readonly followUpSubmissions: ICrudClient<any>;

  readonly meetings: ICrudClient<any>;

  readonly programmePeriods: ICrudClient<any>;

  readonly indicators: ICrudClient<any>;

  readonly indicatorValues: ICrudClient<any>;

  readonly indicatorValueDetails: ICrudClient<any>;

  readonly regrantingProgrammeIndicators: ICrudClient<any>;

  readonly openCallFiles: ICrudClient<any>;
  readonly rfFiles: ICrudClient<any>;
}
