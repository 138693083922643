export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "type",
    required: true,
    label: "Activity Type",
    align: "left",
    sortable: true,
    field: (i: any) => i.activityType ? i.activityType.value: ''
  },
  {
    name: "level",
    required: true,
    label: "Level",
    align: "left",
    sortable: true,
    field: (i: any) => i.activityLevel
  }
];
