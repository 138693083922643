import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberRequestsQueryBuilder
  implements IODataQueryBuilder {
  public readonly defaultFilter: string;
  constructor(id: string) {
    this.entity = `RequestSubmissions`;
    this.defaultFilter = `Session/HouseholdMemberAction/HouseholdMemberId eq ${id}`;
  }
  public entity: string;
  public readonly select = [];
  public readonly expand = [
    "Request($expand=Questionnaire)",
    "NextStep($expand=Questionnaire)",
    "RequestQuestionnaireSubmission",
    "NextStepQuestionnaireSubmission",
    "Session($expand=HouseholdMemberAction($expand=Project,ActionActionType($expand=ActionType)))",
    "FollowUp",
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Name, '${val}')`];
    return arr.join(" or ");
  }
}
