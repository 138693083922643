import IODataQueryBuilder from "../IODataQueryBuilder";

export default class SessionQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Actions";
  public readonly select = [];
  public readonly expand = ["HouseholdMemberAction", "User"];
  public filter(val: string): string {
    const arr: string[] = [`contains(SessionId, '${val}')`];
    return arr.join(" or ");
  }
}
