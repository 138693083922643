import IODataQueryBuilder from "../IODataQueryBuilder";

export default class IndicatorQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Indicators";
  public readonly select = [];
  public readonly expand = [
    "SourceOfVerification",
    "FrequencyOfReporting",
    "ParentIndicator",
    "ResultFrameworkBreakDowns($expand=Options)",
    "IndicatorValues($expand=Period,BreakdownOption($expand=ResultFrameworkBreakdown))"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
