import IODataQueryBuilder from "../IODataQueryBuilder";

export default class AppealQueryBuilder implements IODataQueryBuilder {
  constructor(id = "") {
    if (id) {
      this.entity = `OpenCalls(${id})/Appeals`;
    } else {
      this.entity = "/Appeals";
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["DecisionDocument", "Document", "Applicant"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
