export default [
  {
    name: "topic",
    required: true,
    label: "Topic",
    align: "left",
    sortable: true,
    field: (i: any) => i.topic
  },
  {
    name: "dateOfSession",
    required: true,
    label: "Date of Session",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.dateOfSession ? new Date(Date.parse(i.dateOfSession)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "End Date",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.endDate ? new Date(Date.parse(i.endDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "project/title",
    required: true,
    label: "Project",
    align: "left",
    sortable: true,
    field: (i: any) => i.project ? i.project.title : ''
  },
  {
    name: "activity/name",
    required: true,
    label: "Activity",
    align: "left",
    sortable: true,
    field: (i: any) => i.activity ? i.activity.name : ''
  },
  {
    name: "organizations",
    required: true,
    label: "Organizations",
    align: "left",
    sortable: false,
    field: (i: any) => i.organizations ? i.organizations.map((o: any) => o.value).join(", ") : ''
  },
  {
    name: "facilitator",
    required: true,
    label: "Facilitator",
    align: "left",
    sortable: false,
    field: (i: any) => i.facilitator ? i.facilitator.firstName + " " + i.facilitator.lastName : ""
  },
  {
    name: "numberOfPArticipants",
    required: true,
    label: "No of Participants",
    align: "right",
    sortable: false,
    field: (i: any) => i.noOfParticipants
  }
];
