import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

import ProgramsColumns from "@/data/columns/Programs";
import ProjectsColumns from "@/data/columns/Projects";
import UsersColumns from "@/data/columns/Users";
import UserGroupsColumns from "@/data/columns/UserGroups";
import ActivitiesColumns from "@/data/columns/Activities";

import ActivityRecords from "@/data/mock/ActivityRecords";
import ActivityRecordsColumns from "@/data/columns/ActivityRecords";

import Beneficiaries from "@/data/mock/HouseholdMember";
import BeneficiariesColumns from "@/data/columns/HouseholdMembers";

import Questionnaires from "@/data/mock/Questionnaires";
import QuestionnairesColumns from "@/data/columns/Questionnaires";

import CurrenciesColumns from "@/data/columns/Currency";
import IDataService from "@/lib/IDataService";
import ICrudClient from "@/lib/ICrudClient";
import ODataProvider from "@/lib/odata/ODataProvider";
import { Prescreening } from "@/data/models/Questionnaires";
import PrescreeningColumns from "@/data/columns/Prescreening";
import FollowUpColumns from "@/data/columns/FollowUp";
import SettingsColumns from "@/data/columns/Settings";
import lazyLoadView from "./lazyLoadView";
import crudRouteGenerator from "./crudRouteGenerator";
import ActivityColumns from "@/data/columns/Activities";
import getEnumRoutes from "./index.enums";
import FileColumns from "@/data/columns/Files";
import ActionTypesColumns from "@/data/columns/ActionTypes";
import DonorColumns from "@/data/columns/Donors";
import ActionsColumns from "@/data/columns/Actions";
import OpenCallsColumns from "@/data/columns/OpenCalls";
import CriteriaColumns from "@/data/columns/Criteria";
import AlwaysAvailableQuestionnaireSubmission from "@/data/columns/AlwaysAvailableQuestionnaireSubmission";
import StrategicCommunicationColumns from "@/data/columns/StrategicCommunication";
import CommunicationTypesColumn from "@/data/columns/CommunicationTypes";
import ResultFrameworkBreakDownColumns from "@/data/columns/ResultFrameworkBreakdown";
import CompanyColumns from "@/data/columns/Companies";
import Enums from "@/data/columns/Enums";
import AuthService from "@/lib/AuthService";
import ConsentForms from "@/data/columns/ConsentForms";
Vue.use(VueRouter);

export default function getRoutes(service: IDataService, auth: AuthService) {
  const perm = auth.perm.bind(auth);
  const enumRoutes = getEnumRoutes(service, auth);
  const enumListItems = enumRoutes.map((r: RouteConfig) => r.meta);
  const routes: Array<RouteConfig> = [
    {
      path: "/authentication",
      name: "Authentication",
      component: () => import("../views/Authentication.vue"),
      props: r => ({ redirect: r.query.redirect })
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/Register.vue")
    },
    {
      path: "/",
      component: () => import("@/views/Layout.vue"),
      children: [
        ...enumRoutes,
        {
          path: "",
          name: "Home",
          component: () => lazyLoadView(import("@/views/Home.vue"))
        },
        {
          path: "help",
          name: "Help",
          component: () => lazyLoadView(import("@/views/Help.vue"))
        },
        {
          path: "AddServiceWizard/:memberId",
          name: "AddServiceWizard",
          component: () =>
            lazyLoadView(import("@/components/forms/AddServiceWizard.vue")),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            memberId: r.params.memberId
          })
        },
        {
          path: "reports",
          name: "Reports",
          component: () => lazyLoadView(import("@/views/Reports.vue")),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view)
          })
        },
        {
          path: "PrescreeningsReport",
          name: "PrescreeningsReport",
          component: () =>
            lazyLoadView(import("@/components/reports/PrescreeningReport.vue")),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "SocialIntegrationReport",
          name: "SocialIntegrationReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/SocialIntegrationReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "LegalServicesReport",
          name: "LegalServicesReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/LegalServicesReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "CaseManagementReport",
          name: "CaseManagementReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/CaseManagementReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "CommunityActivitiesReport",
          name: "CommunityActivitiesReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/CommunityActivitiesReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "CommunicationReport",
          name: "CommunicationReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/CommunicationReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "ExtractionReport",
          name: "ExtractionReport",
          component: () =>
            lazyLoadView(
              import("@/components/reports/ExtractionReport.vue")
            ),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },

        {
          path: "reportsSelect",
          name: "ReportsSelect",
          component: () => lazyLoadView(import("@/views/ReportsSelect.vue")),
          props: (r: Route) => ({
            addPermission: perm(p => p.reports.view),
            reportType: r.params.reportType,
            title: r.params.title
          })
        },
        {
          path: "calendar",
          name: "Calendar",
          component: () =>
            lazyLoadView(import("@/components/MiniCalendarTable.vue")),
          props: (r: Route) => ({
            viewPermission: perm(p => p.beneficiaries.view),
            editPermission: perm(p => false),
            addPermission: perm(p => false),
            deletePermission: perm(p => false)
          })
        },
        {
          path: "intake-form/:prescreeningId/:projectId?/:beneficiaryId?",
          name: "IntakeForm",
          component: () =>
            lazyLoadView(import("@/components/forms/IntakeForm.vue")),
          props: (r: Route) => ({
            addPermission: perm(p => p.beneficiaries.add),
            prescreeningId: r.params.prescreeningId,
            projectId: r.params.projectId,
            beneficiaryId: r.params.beneficiaryId,
            crud: service.crud.intakeFormSubmissions,
            projectsProvider: service.providers.projects,
            prescreeningProvider: service.providers.prescreenings,
            gendersProvider: service.providers.genders,
            nationalitiesProvider: service.providers.nationalities,
            questionnaireProvider: service.providers.questionnaires,
            householdMemberRolesProvider:
              service.providers.householdMemberRoles,
            housingTypesProvider: service.providers.housingTypes,
            usersProvider: service.providers.users,
            legalStatusesProvider: service.providers.legalStatuses,
            householdTypesProvider: service.providers.householdTypes
          })
        },
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/donors",
          columns: DonorColumns,
          provider: service.providers.donors,
          crud: service.crud.donors,
          listTitle: "Donors",
          formComponent: lazyLoadView(
            import("@/components/forms/DonorForm.vue")
          ),
          addRouteName: "DonorsForm",
          listRouteName: "DonorsList",
          props: (r: Route) => {
            return {
              name: "Donor",
              sortBy: "Name"
            };
          }
        }),
        {
          path: "case-management/:id",
          component: () => lazyLoadView(import("@/views/CaseManagement.vue")),
          props: (r: Route) => ({
            viewPermission: perm(p => p.beneficiaries.view),
            editPermission: perm(p => p.beneficiaries.edit),
            addPermission: perm(p => p.beneficiaries.add),
            deletePermission: perm(p => p.beneficiaries.delete),
            id: r.params.id
          }),
          children: [
            {
              name: "CaseManagement",
              path: "",
              component: () =>
                lazyLoadView(
                  import("@/components/forms/HouseholdMemberBasicDataForm.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.view),
                editPermission: perm(p => p.beneficiaries.edit),
                addPermission: perm(p => p.beneficiaries.add),
                deletePermission: perm(p => p.beneficiaries.delete),
                id: r.params.id,
                provider: service.providers.householdMembers,
                routeAfterSaveAndClose: (item: any) => ({
                  name: "BeneficiariesList"
                })
              })
            },
            {
              path: "projects",
              name: "HouseholdMemberProjects",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberProjects.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.addProject),
                editPermission: perm(p => p.beneficiaries.addProject),
                addPermission: perm(p => p.beneficiaries.addProject),
                deletePermission: perm(p => p.beneficiaries.addProject),
                id: r.params.id
              })
            },
            {
              path: "plan",
              name: "HouseholdMemberPdp",
              component: () =>
                lazyLoadView(import("@/components/HouseholdMemberPdp.vue")),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.pdp),
                editPermission: perm(p => p.beneficiaries.pdp),
                addPermission: perm(p => p.beneficiaries.pdp),
                deletePermission: perm(p => p.beneficiaries.pdp),
                id: r.params.id
              })
            },
            {
              path: "history",
              name: "HouseholdMemberActions",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberActionsTable.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.viewAction),
                editPermission: perm(p => p.beneficiaries.editAction),
                addPermission: perm(p => p.beneficiaries.addAction),
                deletePermission: perm(p => p.beneficiaries.deleteAction),
                id: r.params.id
              })
            },
            {
              path: "referrlas",
              name: "HouseholdMemberReferrals",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberReferralsTable.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.viewAction),
                editPermission: perm(p => p.beneficiaries.editAction),
                addPermission: perm(p => p.beneficiaries.addAction),
                deletePermission: perm(p => p.beneficiaries.deleteAction),
                id: r.params.id
              })
            },
            {
              path: "consents",
              name: "HouseholdMemberConsents",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberConsents.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.viewAction),
                editPermission: perm(p => p.beneficiaries.editAction),
                addPermission: perm(p => p.beneficiaries.addAction),
                deletePermission: perm(p => p.beneficiaries.deleteAction),
                id: r.params.id
              })
            },
            {
              path: "attachments",
              name: "HouseholdMemberAttachments",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberAttachments.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.viewAction),
                editPermission: perm(p => p.beneficiaries.editAction),
                addPermission: perm(p => p.beneficiaries.addAction),
                deletePermission: perm(p => p.beneficiaries.deleteAction),
                id: r.params.id
              })
            },
            {
              path: "status",
              name: "HouseholdMemberStatus",
              component: () =>
                lazyLoadView(import("@/components/HouseholdMemberStatus.vue")),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.viewAction),
                editPermission: perm(p => p.beneficiaries.editAction),
                addPermission: perm(p => p.beneficiaries.addAction),
                deletePermission: perm(p => p.beneficiaries.deleteAction),
                id: r.params.id
              })
            },
            {
              path: "prescreening",
              name: "HouseHoldMemberPrescreening",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseHoldMemberPrescreening.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.view),
                editPermission: perm(p => p.prescreening.edit),
                id: r.params.id
              })
            },
            {
              path: "note",
              name: "HouseholdMemberNote",
              component: () =>
                lazyLoadView(
                  import("@/components/HouseholdMemberNote.vue")
                ),
              props: (r: Route) => ({
                viewPermission: perm(p => p.beneficiaries.view),
                editPermission: perm(p => p.prescreening.edit),
                id: r.params.id
              })
            }
          ]
        },
        {
          path: "prescreening",
          name: "Prescreening",
          component: () =>
            lazyLoadView(import("@/components/forms/PrescreeningView.vue")),
          props: {
            viewPermission: perm(p => p.prescreenings.view),
            addPermission: perm(p => p.prescreenings.add),
            deletePermission: perm(p => p.prescreenings.delete),
            crud: service.crud.prescreenings,
            provider: service.providers.prescreenings,
            questionnaireProvider: service.providers.questionnaires,
            prescreeningActionTakenProvider:
              service.providers.prescreeningActionTaken,
            usersProvider: service.providers.users
          }
        },
        {
          path: "/open-calls",
          component: () => lazyLoadView(import("@/views/SubPage.vue")),
          children: [
            {
              name: "OpenCallList",
              path: "/open-calls",
              component: () =>
                lazyLoadView(import("@/views/OpenCallTable.vue")),
              props: (r: Route) => {
                return {
                  addRoute: () => ({ name: "OpenCall", params: { id: "new" } }),
                  viewRoute: (item: any) => ({
                    name: "OpenCall",
                    params: { id: item.id }
                  }),
                  viewPermission: perm(p => p.openCalls.view),
                  editPermission: perm(p => p.openCalls.edit),
                  addPermission: perm(p => p.openCalls.add),
                  deletePermission: perm(p => p.openCalls.delete),
                  columns: OpenCallsColumns,
                  provider: service.providers.openCalls,
                  crud: service.crud.openCalls,
                  permission: () => true
                };
              }
            },
            {
              path: "open-calls/:id",
              component: () => lazyLoadView(import("@/views/OpenCall.vue")),
              props: (r: Route) => {
                return {
                  viewPermission: perm(p => p.openCalls.view),
                  editPermission: perm(p => p.openCalls.edit),
                  addPermission: perm(p => p.openCalls.add),
                  deletePermission: perm(p => p.openCalls.delete),
                  id: r.params.id,
                  provider: service.providers.openCalls,
                  crud: service.crud.openCalls,
                  projectsProvider: service.providers.projects
                };
              },
              children: [
                {
                  name: "OpenCall",
                  path: "",
                  component: () =>
                    lazyLoadView(import("@/components/forms/OpenCallForm.vue")),
                  props: (r: Route) => {
                    return {
                      viewPermission: perm(p => p.openCalls.view),
                      editPermission: perm(p => p.openCalls.edit),
                      addPermission: perm(p => p.openCalls.add),
                      deletePermission: perm(p => p.openCalls.delete),
                      id: r.params.id,
                      provider: service.providers.openCalls,
                      crud: service.crud.openCalls,
                      projectsProvider: service.providers.projects
                    };
                  }
                },
                {
                  name: "Criteria",
                  path: "criteria",
                  component: () =>
                    lazyLoadView(import("@/components/Criteria.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    openCallId: r.params.id,
                    crud: service.crud.criteria,
                    projectsProvider: service.providers.projects
                  })
                },
                {
                  name: "Applicants",
                  path: "applicants",
                  component: () =>
                    lazyLoadView(import("@/components/Applicants.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    openCallId: r.params.id,
                    crud: service.crud.applicants,
                    projectsProvider: service.providers.projects
                  })
                },
                {
                  name: "Scores",
                  path: "scores",
                  component: () =>
                    lazyLoadView(import("@/components/Scores.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    id: r.params.id,
                    provider: service.providers.scores,
                    crud: service.crud.scores
                  })
                },
                {
                  name: "Results",
                  path: "results",
                  component: () =>
                    lazyLoadView(import("@/components/Results.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    openCallId: r.params.id,
                    provider: service.providers.scores,
                    crud: service.crud.scores
                  })
                },
                {
                  name: "Appeals",
                  path: "appeals",
                  component: () =>
                    lazyLoadView(import("@/views/OpenCallAppeals.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    openCallId: r.params.id,
                    isTab: r.params.isTab
                  })
                },
                {
                  name: "OpenCallFiles",
                  path: "openCallFiles",
                  component: () =>
                    lazyLoadView(import("@/components/OpenCallFilesTable.vue")),
                  props: (r: Route) => ({
                    viewPermission: perm(p => p.openCalls.view),
                    editPermission: perm(p => p.openCalls.edit),
                    addPermission: perm(p => p.openCalls.add),
                    deletePermission: perm(p => p.openCalls.delete),
                    openCallId: r.params.id,
                    crud: service.crud.openCallFiles
                  })
                }
              ]
            }
          ]
        },

        {
          path: "questionnaire/:id/:beneficiaryId?",
          name: "QuestionnaireSubmit",
          component: () =>
            lazyLoadView(import("@/components/forms/QuestionnaireSubmit.vue")),
          props: (r: Route) => {
            return {
              //TODO: WHAT PERMS?
              questionnaireId: r.params.id,
              beneficiaryId: r.params.beneficiaryId,
              routeAfterSaveAndClose: (item: any) => {
                if (r.params.beneficiaryId) {
                  return {
                    name: "HouseholdMemberPdp",
                    params: { id: r.params.beneficiaryId }
                  };
                } else {
                  return {
                    name: "HouseholdMemberPdp",
                    params: { id: item.beneficiaryId }
                  };
                }
              },
              routeAfterSave: (item: any) => {
                return {
                  name: "QuestionnaireSubmit",
                  params: { id: item.id }
                };
              }
            };
          }
        },
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/currencies",
          contextCellName: "value",
          columns: CurrenciesColumns,
          provider: service.providers.currencies,
          crud: service.crud.currencies,
          listTitle: "Currencies",
          formComponent: lazyLoadView(
            import("@/components/forms/CurrencyForm.vue")
          ),
          addRouteName: "CurrenciesForm",
          listRouteName: "CurrenciesList",
          props: (r: Route) => {
            return {
              sortBy: "Value"
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/follow-up",
          contextCellName: "value",
          columns: FollowUpColumns,
          provider: service.providers.followUp,
          crud: service.crud.followUp,
          listTitle: "Follow Up",
          formComponent: lazyLoadView(
            import("@/components/forms/FollowUpForm.vue")
          ),
          addRouteName: "FollowUpForm",
          listRouteName: "FollowUpList",
          props: (r: Route) => {
            return {
              sortBy: "Text"
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/action-types",
          columns: ActionTypesColumns,
          provider: service.providers.actionTypes,
          crud: service.crud.actionTypes,
          listTitle: "Service Types",
          formComponent: lazyLoadView(
            import("@/components/forms/ActionTypeForm.vue")
          ),
          addRouteName: "ActionTypesForm",
          listRouteName: "ActionTypesList",
          props: (r: Route) => {
            return {
              sortBy: "Name"
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/actions",
          columns: ActionsColumns,
          provider: service.providers.actions,
          crud: service.crud.actions,
          listTitle: "Services",
          formComponent: lazyLoadView(
            import("@/components/forms/ActionForm.vue")
          ),
          addRouteName: "ActionsForm",
          listRouteName: "ActionsList",
          props: (r: Route) => {
            return {
              sortBy: "Name"
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/activities",
          columns: ActivitiesColumns,
          provider: service.providers.activities,
          crud: service.crud.activities,
          listTitle: "Activities",
          formComponent: lazyLoadView(
            import("@/components/forms/ActivitiesForm.vue")
          ),
          addRouteName: "ActivitiesForm",
          listRouteName: "ActivitiesList",
          props: r => ({
            sortBy: "Name"
          })
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.programs.view),
          editPermission: perm(p => p.programs.edit),
          addPermission: perm(p => p.programs.add),
          deletePermission: perm(p => p.programs.delete),
          path: "/programmes",
          contextCellName: "title",
          columns: ProgramsColumns,
          provider: service.providers.programs,
          crud: service.crud.programs,
          extractions: true,
          listTitle: "Programmes",
          formComponent: lazyLoadView(
            import("@/components/forms/ProgramsForm.vue")
          ),
          addRouteName: "ProgramsForm",
          listRouteName: "ProgramsList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/communication-types",
          contextCellName: "name",
          columns: CommunicationTypesColumn,
          provider: service.providers.communicationTypes,
          crud: service.crud.communicationTypes,
          listTitle: "Communication Types",
          formComponent: lazyLoadView(
            import("@/components/forms/CommunicationTypeForm.vue")
          ),
          addRouteName: "CommunicationTypeForm",
          listRouteName: "CommunicationTypeList",
          props: (r: Route) => {
            return {
              //
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/result-Framework-Breakdowns",
          contextCellName: "name",
          columns: ResultFrameworkBreakDownColumns,
          provider: service.providers.resultFrameworkBreakdowns,
          crud: service.crud.resultFrameworkBreakdowns,
          listTitle: "Framework Breakdowns",
          formComponent: lazyLoadView(
            import("@/components/forms/ResultFrameworkBreakdownForm.vue")
          ),
          addRouteName: "ResultFrameworkBreakdownForm",
          listRouteName: "ResultFrameworkBreakdownList",
          props: (r: Route) => {
            return {
              //
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/companies",
          contextCellName: "name",
          columns: CompanyColumns,
          provider: service.providers.companies,
          crud: service.crud.companies,
          listTitle: "Companies",
          formComponent: lazyLoadView(
            import("@/components/forms/CompaniesForm.vue")
          ),
          addRouteName: "CompaniesForm",
          listRouteName: "CompaniesList",
          props: (r: Route) => {
            return {
              //
            };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/consent-Form",
          columns: ConsentForms,
          provider: service.providers.consentForms,
          crud: service.crud.consentForms,
          listTitle: "Consent Forms",
          formComponent: lazyLoadView(
            import("@/components/forms/ConsentForm.vue")
          ),
          addRouteName: "ConsentForm",
          listRouteName: "ConsentFormList",
          props: r => ({
            //
          })
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.strategicCommunications.view),
          editPermission: perm(p => p.strategicCommunications.edit),
          addPermission: perm(p => p.strategicCommunications.add),
          deletePermission: perm(p => p.strategicCommunications.delete),
          path: "/strategic-communications",
          contextCellName: "title",
          extractions: true,
          columns: StrategicCommunicationColumns,
          provider: service.providers.strategicCommunications,
          crud: service.crud.strategicCommunications,
          listTitle: "Strategic Communication and advocacy",
          formComponent: lazyLoadView(
            import("@/components/forms/StrategicCommunicationsForm.vue")
          ),
          addRouteName: "StrategicCommunicationsForm",
          listRouteName: "StrategicCommunicationsList"
        }),
        crudRouteGenerator({
          viewPermission: perm(
            p => p.alwaysAvailableQuestionnaireSubmissions.view
          ),
          editPermission: perm(
            p => p.alwaysAvailableQuestionnaireSubmissions.edit
          ),
          addPermission: perm(
            p => p.alwaysAvailableQuestionnaireSubmissions.add
          ),
          deletePermission: perm(
            p => p.alwaysAvailableQuestionnaireSubmissions.delete
          ),
          path: "/always-available-questionnaire-submissions",
          contextCellName: "title",
          columns: AlwaysAvailableQuestionnaireSubmission,
          provider: service.providers.alwaysAvailableQuestionnaireSubmissions,
          crud: service.crud.questionnaireSubmissions,
          listTitle: "Submissions of Incidence reports",
          tableComponent: lazyLoadView(
            import("@/views/AlwaysAvailableQuestionnaireSubmissionTable.vue")
          ),
          formComponent: lazyLoadView(
            import(
              "@/components/forms/AlwaysAvailableQuestionnaireSubmissionForm.vue"
            )
          ),
          // addRouteName: "AlwaysAvailableQuestionnaireSubmissionForm",
          listRouteName: "AlwaysAvailableQuestionnaireSubmissionList",
          props: (r: Route) => {
            return { questionnairesProvider: service.providers.questionnaires };
          }
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.projects.view),
          editPermission: perm(p => p.projects.edit),
          addPermission: perm(p => p.projects.add),
          deletePermission: perm(p => p.projects.delete),
          path: "/projects",
          contextCellName: "title",
          columns: ProjectsColumns,
          provider: service.providers.projects,
          crud: service.crud.projects,
          listTitle: "Projects",
          tableComponent: lazyLoadView(import("@/views/ProjectTable.vue")),
          formComponent: lazyLoadView(
            import("@/components/forms/ProjectsForm.vue")
          ),
          addRouteName: "ProjectsForm",
          listRouteName: "ProjectsList"
        }),
        crudRouteGenerator<Prescreening>({
          viewPermission: perm(p => p.prescreenings.view),
          editPermission: perm(p => p.prescreenings.edit),
          deletePermission: perm(p => p.prescreenings.delete),
          path: "/prescreenings",
          columns: PrescreeningColumns,
          provider: service.providers.prescreenings,
          crud: service.crud.prescreenings,
          listTitle: "Prescreenings",
          tableComponent: lazyLoadView(import("@/views/PrescreeningTable.vue")),
          formComponent: lazyLoadView(
            import("@/components/forms/PrescreeningResults.vue")
          ),
          listRouteName: "PrescreeningsList",
          viewRouteName: "PrescreeningsForm"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.questionnaires.view),
          editPermission: perm(p => p.questionnaires.edit),
          addPermission: perm(p => p.questionnaires.add),
          deletePermission: perm(p => p.questionnaires.delete),
          path: "/questionnaires",
          columns: QuestionnairesColumns,
          provider: service.providers.questionnaires,
          crud: service.crud.questionnaires,
          listTitle: "Questionnaires",
          tableComponent: lazyLoadView(
            import("@/views/QuestionnaireTable.vue")
          ),
          formComponent: lazyLoadView(
            import("@/components/forms/QuestionnairesForm.vue")
          ),
          addRouteName: "QuestionnairesForm",
          listRouteName: "QuestionnairesList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.beneficiaries.view),
          editPermission: perm(p => p.beneficiaries.edit),
          addPermission: perm(p => p.beneficiaries.add),
          deletePermission: perm(p => p.beneficiaries.delete),
          path: "/beneficiaries",
          contextCellName: "lastName",
          columns: BeneficiariesColumns,
          provider: service.providers.householdMembers,
          crud: service.crud.householdMembers,
          listTitle: "Beneficiaries",
          formComponent: lazyLoadView(
            import("@/components/forms/HouseholdMemberBasicDataForm.vue")
          ),
          tableComponent: lazyLoadView(
            import("@/views/BeneficiariesTable.vue")
          ),
          listRouteName: "BeneficiariesList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.beneficiaries.view),
          editPermission: perm(p => p.beneficiaries.edit),
          addPermission: perm(p => p.beneficiaries.add),
          deletePermission: perm(p => p.beneficiaries.delete),
          path: "/beneficiaries",
          contextCellName: "lastName",
          columns: BeneficiariesColumns,
          provider: service.providers.householdMembers,
          crud: service.crud.householdMembers,
          listTitle: "Beneficiaries",
          formComponent: lazyLoadView(
            import("@/components/forms/HouseholdMemberBasicDataForm.vue")
          ),
          tableComponent: lazyLoadView(
            import("@/views/BeneficiariesFindTable.vue")
          ),
          listRouteName: "BeneficiariesFindTable"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.projectManager.is),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/users",
          contextCellName: "email",
          columns: UsersColumns,
          provider: service.providers.users,
          crud: service.crud.users,
          listTitle: "Users",
          formComponent: lazyLoadView(
            import("@/components/forms/UserForm.vue")
          ),
          addRouteName: "UserForm",
          listRouteName: "UserList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/user-groups",
          contextCellName: "name",
          columns: UserGroupsColumns,
          provider: service.providers.userGroups,
          crud: service.crud.userGroups,
          listTitle: "User Groups",
          formComponent: lazyLoadView(
            import("@/components/forms/UserGroupForm.vue")
          ),
          addRouteName: "UserGroupForm",
          listRouteName: "UserGroupList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => p.activities.view),
          editPermission: perm(p => p.activities.edit),
          addPermission: perm(p => p.activities.add),
          deletePermission: perm(p => p.activities.delete),
          path: "/activity-records",
          contextCellName: "topic",
          extractions: true,
          columns: ActivityRecordsColumns,
          provider: service.providers.activityRecordsTable,
          crud: service.crud.activityRecords,
          listTitle: "Activity Records",
          formComponent: lazyLoadView(
            import("@/components/forms/ActivityRecordsForm.vue")
          ),
          addRouteName: "ActivityRecordsForm",
          listRouteName: "ActivityRecordsList"
        }),
        crudRouteGenerator({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/files",
          contextCellName: "name",
          columns: FileColumns,
          provider: service.providers.uploadedFiles,
          crud: service.crud.uploadedFiles,
          tableComponent: lazyLoadView(
            import("@/views/UploadedFilesTable.vue")
          ),
          listTitle: "Files",
          listRouteName: "FilesList"
        }),
        crudRouteGenerator<any>({
          viewPermission: perm(p => false),
          editPermission: perm(p => false),
          addPermission: perm(p => false),
          deletePermission: perm(p => false),
          path: "/settings",
          columns: SettingsColumns,
          provider: service.providers.settings,
          crud: service.crud.settings,
          listTitle: "Settings",
          tableComponent: lazyLoadView(import("@/views/SettingsTable.vue")),
          formComponent: lazyLoadView(
            import("@/components/forms/SettingsForm.vue")
          ),
          listRouteName: "SettingsList",
          viewRouteName: "SettingsForm",
          props: (r: Route) => {
            return {
              addRoute: () => ({
                name: "SettingsForm",
                params: {
                  id: "new"
                }
              })
            };
          }
        })
      ],
      props: (r: Route) => ({
        enumListItems: enumListItems
      })
    },
    {
      path: "/about",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
      path: "*",
      component: () => lazyLoadView(import("@/views/NotFound.vue"))
    }
  ];

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

  return router;
}
