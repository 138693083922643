export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "actionType",
    required: true,
    label: "Service Type",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.actionActionTypes
        ? i.actionActionTypes.map((a: any) => a.actionType.name).join(", ")
        : ""
  },
  {
    name: "description",
    required: true,
    label: "Description",
    align: "left",
    sortable: true,
    field: (i: any) => i.description
  },
  {
    name: "hasMultiples",
    required: true,
    label: "Multiple",
    align: "left",
    sortable: true,
    field: (i: any) => i.hasMultiples
  },
  {
    name: "assignCompanies",
    required: true,
    label: "Assign Companies",
    align: "left",
    sortable: true,
    field: (i: any) => i.assignCompanies
  }
];
