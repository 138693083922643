import IODataQueryBuilder from "@/lib/odata/IODataQueryBuilder";

export default class ExtractionProgrammsQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Programmes";
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}