import IODataQueryBuilder from "../IODataQueryBuilder";
import CommunicationActivityQueryBuilder from "./CommunicationActivityQueryBuilder";

export default class ProjectCommunicationActivityQueryBuilder extends CommunicationActivityQueryBuilder {
  constructor(projectId: string) {
    super();
    this.entity = `Projects(${projectId})/CommunicationActivities`;
  }
  public readonly entity: string;
}
