import Vue from "vue";
import _Vue from "vue";
import App from "./App.vue";
import getRoutes from "./router";
import "./quasar";
import VueMeta from "vue-meta";
import moment from "moment";
import "@/lib/groupBy";
import IDataService from "./lib/IDataService";
import InMemoryDataService from "./lib/InMemory/InMemoryDataService";
import ODataService from "./lib/odata/ODataService";
import axios from "axios";
import { ProgramTypes } from "@/data/models/Programs";
import { QuestionnaireTypes } from "./data/models/Questionnaires";
import { ProjectTypes } from "./data/models/Projects";
import { WebStorageStateStore } from "oidc-client";
import AuthService from "./lib/AuthService";
import * as GoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

Vue.use(GoogleMaps, {   
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places"
}});

const STS_DOMAIN: string = process.env.VUE_APP_SSO_ENDPOINT;
const auth = new AuthService(
  {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: STS_DOMAIN,
    client_id: "vuejs",
    redirect_uri: window.location.origin + "/callback.html",
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + "/silent-renew.html",
    response_type: "code",
    scope: "openid email profile permissions offline_access",
    post_logout_redirect_uri: window.location.origin + "/signout-callback-oidc",
    filterProtocolClaims: true
  },
  axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
  })
);

Vue.use(VueMeta);

const momentPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$moment = moment;
  }
};
Vue.use(momentPlugin);

const rulesPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$rules = {
      required: (str: any) =>
        (str !== undefined && str !== null && str.toString().length !== 0) ||
        "Required"
    };
  }
};
Vue.use(rulesPlugin);

const enumsPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$enums = {
      programTypes: ProgramTypes,
      questionnaireTypes: QuestionnaireTypes,
      projectTypes: ProjectTypes
    };
  }
};
Vue.use(enumsPlugin);

let service: IDataService | null = null;
if (process.env.VUE_APP_USE_MOCK === "true") {
  service = new InMemoryDataService();
} else {
  service = new ODataService(
    axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT }),
    auth
  );
}
const router = getRoutes(service, auth);

const servicePlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$service = service;
  }
};
Vue.use(servicePlugin);

class Handler {
  private dict: { [k: string]: boolean } = {};

  get(target: any, prop: any, receiver: any) {
    if (!this.dict[prop]) {
      this.dict[prop] = false;
    }
    return this.dict[prop];
  }
  set(target: any, prop: any, value: boolean) {
    this.dict[prop] = value;
    return true;
  }
}

const dynamicLoadingPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Object.defineProperty(Vue.prototype, "$proxy", {
      get: function $proxy() {
        if (!this.__proxy) {
          this.__proxy = new Proxy(Vue, new Handler());
        }
        return this.__proxy;
      }
    });
  }
};
Vue.use(dynamicLoadingPlugin);

const runAsyncPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$runAsync = async function(name: string, callback: any) {
      this.$proxy[name] = true;
      this.$forceUpdate();
      try {
        await callback();
      } finally {
        this.$proxy[name] = false;
        this.$forceUpdate();
      }
    };
  }
};
Vue.use(runAsyncPlugin);

const authPlugin = {
  install: (Vue: typeof _Vue, options?: any) => {
    Vue.prototype.$auth = Vue.observable(auth);
  }
};
Vue.use(authPlugin);
auth.hookRouter(router);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
