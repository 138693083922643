import IODataQueryBuilder from "../IODataQueryBuilder";

export default class FollowUpSubmissionQueryBuilder
  implements IODataQueryBuilder {
  public readonly entity = "FollowUpsubmissions";
  public readonly select = [];
  public readonly expand = [
    "FollowUp($expand=Questionnaire($expand=Questions($orderby=Priority asc;$expand=Options($orderby=Priority asc),BoundTo($expand=Options,BoundQuestion))))",
    "Submission($expand=Answers($expand=Options,Tuples,File))",
    "UploadedFile"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Value, '${val}')`];
    return arr.join(" or ");
  }
}
