import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberActionSessionsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `HouseholdMemberActions(${id})/Sessions`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "HouseholdMemberAction($expand=ActionActionType)",
    "Purpose",
    "Submissions($count=true;$top=0)"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
