import IODataQueryBuilder from "../IODataQueryBuilder";

export default class UserGroupUsersQueryBuilder implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `UserGroups(${id})/Users`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["UserGroup", "Programs"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
