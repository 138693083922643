import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ActivityRecordTableQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "ActivityRecords";
  public readonly select = [];
  public readonly expand = [
    "Activity",
    "Location",
    "Organizations",
    "Project($select=title)",
    "Facilitator($select=firstname,lastname)"
  ];
  public readonly expandOne = [];
  public filter(val: string): string {
    const arr: string[] = [`contains(Topic, '${val}')`];
    return arr.join(" or ");
  }
}