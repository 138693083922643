import { Programme, Donor } from "./Programs";
import { Activity } from "./Activity";
import { User } from "./Users";
import { Indicator } from "./Indicators";
import { ActivityRecord } from "./ActivityRecord";
import { ActionType } from "./ActionTypes";
import { IBaseModel } from "./IBaseModel";
import { ConsentForm } from "./ConsentForm";
import { Location } from "./Locations";

export interface ProjectActivity extends IBaseModel {
  name: string;
  type: Activity | null;
  multiplicity: boolean;
  dueDate: string;
}
export const ProjectTypes = [
  'OpenCall',
  'Predefined',
  'SmallScale',
];
export interface Project extends IBaseModel {
  title: string;
  type: "OpenCall" | "Predefined" | "SmallScale" | null;
  programme: Programme | null;
  programmeId: string;
  donor: Donor | null;
  roleInPartnership: any;
  roleInPartnershipId: string;
  startDate: string;
  endDate: string;
  contract: string;
  consentForm: ConsentForm | null;
  location?: Location;
  locationId: string;
  callForTenders: string;
  fundAmount: number;
  projectManagers: User[] | null;
  includePersonalDevelopmentPlan: boolean;
  projectActivities: ProjectActivity[];
  indicators: Indicator[];
  activityRecords: ActivityRecord[];
  actions: { name: string; type: ActionType | null }[];
  openCall?: any;
  openCallId?: string;
  projectExtensions: any[] | null;
}
