export default [
  {
    name: "identifier",
    required: true,
    label: "Identifier",
    align: "left",
    sortable: true,
    field: (i: any) => i.identifier
  },
  {
    name: "companyTitle",
    required: true,
    label: "Company Title",
    align: "left",
    sortable: true,
    field: (i: any) => i.companyTitle
  },
  {
    name: "logo",
    required: true,
    label: "Logo",
    align: "left",
    sortable: true,
    field: (i: any) => i.logo
  },
  {
    name: "isActive",
    required: true,
    label: "Active",
    align: "center",
    sortable: true,
    field: (i: any) => i.isActive
  }
];
