import IODataQueryBuilder from "../IODataQueryBuilder";

export default class OpenCallQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "OpenCalls";
  public readonly select = [];
  public readonly expand = [
    "File",
    "FinalFiles",
    "Programme",
    "EligibilityQuestionnaire"
  ];
  public readonly expandOne = [
    "File",
    "FinalFiles",
    "Programme",
    "EligibilityQuestionnaire",
    "Appeals",
    "PuUsers"
  ];
  public filter(val: string): string {
    const arr: string[] = [`contains(Title, '${val}')`];
    return arr.join(" or ");
  }
}
