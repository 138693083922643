import { Guid } from "guid-typescript";

export function toMap(answers: any[]): { [key: string]: any } {
  const map: { [key: string]: any } = {};
  for (const a of answers) {
    map[a.questionId] = a;
  }
  return map;
}

export function toArray(answers: { [key: string]: any }): any[] {
  return Object.values(answers || {})
    .map(ans => {
      const answer = { ...ans };
      if (!answer.id) {
        answer.id = Guid.create().toString();
      }
      if (answer.options) {
        answer.options = answer.options.filter(
          (o: any) => o.id != null && o.id != "other"
        );
      }
      return answer;
    })
    .filter(a => a != null);
}
