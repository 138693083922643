import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ProgrammeIndicatorsQueryBuilder
  implements IODataQueryBuilder {
  constructor(id: string) {
    this.entity = `Programmes(${id})/Indicators`;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [
    "SourceOfVerification",
    "FrequencyOfReporting",
    "ParentIndicator",
    "ResultFrameworkBreakDowns($expand=Options)"
  ];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}
