export default [
  {
    name: "title",
    required: true,
    label: "Title",
    align: "left",
    sortable: true,
    field: (i: any) => i.title
  },
  {
    name: "startDate",
    required: true,
    label: "Start Date",
    align: "left",
    sortable: true,
    field: (i: any) => {
      if (i.startDate) {
        return new Date(Date.parse(i.startDate));
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "End Date",
    align: "left",
    sortable: true,
    field: (i: any) => {
      if (i.startDate) {
        return new Date(Date.parse(i.endDate));
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "callAmount",
    required: true,
    label: "Call Amount",
    align: "right",
    sortable: false,
    field: (i: any) => {
      return i.callAmount;
    }
  }
];
