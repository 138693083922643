import IODataQueryBuilder from "../IODataQueryBuilder";

export default class HouseholdMemberQueryBuilder implements IODataQueryBuilder {
  constructor(filter?: any) {
     this.defaultFilter = filter;
  }
  public readonly defaultFilter: string;
  public readonly entity = "HouseholdMembers";
  public readonly select = [];
  public readonly expand = [
    "Gender",
    "Nationality",
    "LegalStatus",
    "HousingType",
    "HouseholdMemberRole",
    "Household",
    "InTakeFormSubmissions($expand=Project,CaseManagers)",
    "Files",
    "UserResponsible",
  ];
  public readonly expandOne = [
    "Gender",
    "Nationality",
    "LegalStatus",
    "HousingType",
    "HouseholdMemberRole",
    "Household($expand=HouseholdType)",
    "Prescreening($expand=AssignedToUser)",
    "Files",
    "AdditionalInformation($expand=Questionnaire,Answers($expand=Options,Tuples,File))",
    "Relationships($expand=Relationship,InverseRelationship)",
    "InverseRelationships($expand=Relationship,InverseRelationship)",
    "LegalFiles",
    "UserResponsible"
  ];
  public filter(val: string): string {
    const arr: string[] = [
      `contains(FirstName, '${val}')`,
      `contains(MiddleName, '${val}')`,
      `contains(LastName, '${val}')`,
      `contains(cast(DateOfBirth, Edm.String), '${val}')`,
      `contains(Nationality/Value, '${val}')`,
      `contains(LegalStatus/Value, '${val}')`,
      `contains(SocialNumber, '${val}')`,
      `contains(PhoneNumber, '${val}')`,
      `contains(Email, '${val}')`,
      `contains(HousingType/Value, '${val}')`,
      `contains(HouseholdMemberRole/Value, '${val}')`,
      `IntakeFormSubmissions/any(s: contains(s/Project/Title, '${val}'))`
    ];
    return arr.join(" or ");
  }
}
