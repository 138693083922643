import IODataQueryBuilder from "../IODataQueryBuilder";

export default class RFFileQueryBuilder implements IODataQueryBuilder {
  constructor(programmeId?: string | null, projectId?: string) {
    if(programmeId){
      this.entity = `Programmes(${programmeId})/ProgrammeRFFiles`;
    } else if (projectId) {
      this.entity = `Projects(${projectId})/ProjectRFFiles`;
    } else {
      this.entity = '/RFFiles'
    }
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["File", "Project", "Programme", "Promoter"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}