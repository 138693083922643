export default function lazyLoadView(AsyncView: any) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@/views/Loading.vue").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 40,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@/views/Error.vue").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000
  });
  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    }
  });
}