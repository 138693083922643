export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "metrics",
    required: true,
    label: "# of metrics",
    align: "left",
    sortable: true,
    field: (i: any) => (i.metrics ? i.metrics.length : "0")
  }
];
