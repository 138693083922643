import IODataQueryBuilder from "../IODataQueryBuilder";

export default class ActivityRecordQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "ActivityRecords";
  public readonly select = [];
  public readonly expand = [
    "Activity($expand=ActivityType)",
    "ActivityParticipants($expand=Gender,Nationality,Certification)",
    "Location",
    "Organizations",
    "Facilitator",
    "Project",
    "InterpreterLanguages",
    "Documents",
    "EvaluationAttachment",
    "Companies"
  ];
  public readonly expandOne = [];
  public filter(val: string): string {
    const arr: string[] = [`contains(Topic, '${val}')`];
    return arr.join(" or ");
  }
}
