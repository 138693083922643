declare global {
  interface Array<T> {
    groupBy(this: Array<T>, keyGetter: (i: T) => any): Map<any, any>;
  }
}

Array.prototype.groupBy = function<T>(
  this: Array<T>,
  keyGetter: (i: T) => any
): Map<any, any> {
  const map = new Map();
  this.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export {};
