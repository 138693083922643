export const defaultPermissions: { [key: string]: any } = {
  admin: {
    is: false
  },
  projectManager: {
    is: false
  },
  programs: {
    view: false,
    add: false,
    edit: false,
    delete: false
  },
  projects: {
    view: false,
    add: false,
    edit: false,
    delete: false
  },
  questionnaires: {
    view: false,
    add: false,
    edit: false,
    delete: false
  },
  prescreenings: {
    view: false,
    add: false,
    delete: false
  },
  beneficiaries: {
    view: false,
    edit: false,
    pdp: false,
    addProject: false,
    viewAction: false,
    addAction: false,
    editAction: false,
    deleteAction: false,
    files: false
  },
  openCalls: {
    view: false,
    add: false,
    edit: false,
    delete: false
  },
  applicantsAndScores: {
    add: false,
    edit: false,
    delete: false
  },
  activities: {
    view: false,
    edit: false,
    add: false,
    delete: false
  },
  strategicCommunications: {
    view: false,
    edit: false,
    add: false,
    delete: false
  },
  alwaysAvailableQuestionnaireSubmissions: {
    view: false,
    edit: false,
    add: false,
    delete: false
  },
  reports: {
    caseManagement: false,
    prescreening: false,
    socialIntegration: false,
    legalServices: false,
    communityActivities: false,
    communication: false
  }
};

export function clone(item: any) {
  if (item === undefined) {
    return undefined;
  }
  if (item === null) {
    return null;
  }
  const cloneObj: { [key: string]: any } = {};
  for (const attribut of Object.keys(item)) {
    if (Array.isArray(item[attribut])) {
      cloneObj[attribut] = item[attribut].map((subitem: any) => clone(subitem));
    } else if (typeof item[attribut] === "object") {
      cloneObj[attribut] = clone(item[attribut]);
    } else {
      cloneObj[attribut] = item[attribut];
    }
  }
  return cloneObj;
}

export function getPermissions(permissions: any) {
  const ret = JSON.parse(JSON.stringify(defaultPermissions));
  if (!permissions) {
    return ret;
  }
  for (const key of Object.keys(ret)) {
    if (Array.isArray(ret[key])) {
      if (Array.isArray(permissions[key])) {
        ret[key] = permissions[key].map((subitem: any) => clone(subitem));
      }
    } else if (typeof ret[key] === "object") {
      if (Object.keys(permissions).indexOf(key) >= 0) {
        const cloned = clone(permissions[key]);
        if (cloned !== undefined && typeof cloned === "object") {
          ret[key] = { ...ret[key], ...cloned };
        }
      }
    } else {
      if (
        Object.keys(permissions).indexOf(key) >= 0 &&
        !Array.isArray(permissions[key]) &&
        typeof permissions[key] !== "object"
      ) {
        ret[key] = permissions[key];
      }
    }
  }
  return ret;
}
