import IODataQueryBuilder from "../IODataQueryBuilder";

export default class EnumQueryBuilder implements IODataQueryBuilder {
  constructor(entity: string) {
    this.entity = entity;
  }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [`contains(Value, '${val}')`];
    return arr.join(" or ");
  }
}
