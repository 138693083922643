import IODataQueryBuilder from "../IODataQueryBuilder";
import RiskCategoriesQueryBuilder from "./RiskCategoriesQueryBuilder";

export default class AssessmentRiskCategoriesQueryBuilder extends RiskCategoriesQueryBuilder {
  constructor(id: string) {
    super();
    this.entity = `RiskAssessments(${id})/Categories`;
  }
  public readonly entity: string;
}
