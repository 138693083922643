import { Questionnaire } from "../models/Questionnaires";

export default [
  {
    name: "questionnaire",
    required: true,
    label: "Questionnaire",
    align: "left",
    sortable: true,
    field: (val: any) =>
      val && val.questionnaire ? val.questionnaire.name : ""
  },
  {
    name: "submissionDate",
    required: true,
    label: "Submission Date",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.submissionDate ? new Date(Date.parse(i.submissionDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "prescreening",
    label: "Prescreening",
    align: "left",
    sortable: false,
    field: (i: any) => (i.prescreening ? i.prescreening.prescreeningId : "")
  },
  {
    name: "beneficiary",
    label: "Beneficiary",
    align: "left",
    sortable: false,
    field: (val: any) =>
      val && val.beneficiary && val.beneficiary.firstName
        ? val.beneficiary.lastName + " " + val.beneficiary.firstName
        : ""
  }
];
