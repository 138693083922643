import { Programme } from "../models/Programs";

export default [
  {
    name: "title",
    required: true,
    label: "Title",
    align: "left",
    sortable: true,
    field: (i: any) => i.title
  },
  {
    name: "type",
    required: true,
    label: "Programme Type",
    align: "left",
    sortable: true,
    field: (i: any) => (i.type ? i.type : "")
  },
  {
    name: "locations",
    required: true,
    label: "Locations",
    align: "left",
    sortable: true,
    field: (i: any) => (i.locations ? i.locations.map((l: any) => l.value).join(', ') : "")
  },
  {
    name: "startDate",
    required: true,
    label: "Start Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.startDate ? new Date(Date.parse(i.startDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "endDate",
    required: true,
    label: "End Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.endDate ? new Date(Date.parse(i.endDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "fundAmount",
    required: true,
    label: "Total Grant Amount",
    align: "left",
    sortable: true,
    field: (i: Programme) => {
      if (i.currency) {
        return (
          (i.currency.position === 'Pre' ? i.currency.symbol : "") +
          i.fundAmount.toLocaleString() +
          (i.currency.position === "Post" ? i.currency.symbol : "")
        );
      }
      return i.fundAmount.toFixed(2);
    }
  }
];
