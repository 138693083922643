import IODataQueryBuilder from "../IODataQueryBuilder";

export default class PartnerQueryBuilder implements IODataQueryBuilder {
  public readonly entity = "Partners";
  public readonly select = [];
  public readonly expand = [];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}