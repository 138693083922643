export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "hasSessions",
    required: true,
    label: "Has Sessions",
    align: "left",
    sortable: true,
    field: (i: any) => i.hasSessions
  },
  {
    name: "isLegal",
    required: true,
    label: "Legal",
    align: "left",
    sortable: true,
    field: (i: any) => i.isLegal
  },
  {
    name: "isPsychological",
    required: true,
    label: "Psychological",
    align: "left",
    sortable: true,
    field: (i: any) => i.isPsychological
  }
];
