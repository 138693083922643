import IODataQueryBuilder from "../IODataQueryBuilder";

export default class OpenCallFilesQueryBuilder
  implements IODataQueryBuilder {
    constructor(id?: string) {
      if(id){
        this.entity = `OpenCalls(${id})/OpenCallFiles`;
      } else {
        this.entity = '/OpenCallFiles'
      }
    }
  public readonly entity: string;
  public readonly select = [];
  public readonly expand = ["File"];
  public filter(val: string): string {
    const arr: string[] = [];
    return arr.join(" or ");
  }
}